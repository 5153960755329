<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <!--
                  renderComponentCountrySelect: forze re-render (optional)
                  country-language: translate without re-render (optional)
              -->
            <CountrySelect
              id="company-country"
              v-model="companyAddressLocal.country"
              :country-data="companyAddressLocal.country"
              :placeholder="$t('CompanySettings.body.tab2.form.country')"
              :error-messages="errorMessages.country"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <RegionSelect
              id="address-region"
              v-model="companyAddressLocal.region"
              :country-data="companyAddressLocal.country"
              :region-data="companyAddressLocal.region"
              :placeholder="$t('CompanySettings.body.tab2.form.region')"
              :error-messages="errorMessages.region"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="companyAddressLocal.street"
              outlined
              :label="$t('CompanySettings.body.tab2.form.street')"
              hide-details="auto"
              :error-messages="errorMessages.street"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="companyAddressLocal.city"
              outlined
              :label="$t('CompanySettings.body.tab2.form.city')"
              hide-details="auto"
              :error-messages="errorMessages.city"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="companyAddressLocal.zip"
              outlined
              :label="$t('CompanySettings.body.tab2.form.zip')"
              hide-details="auto"
              :error-messages="errorMessages.zip"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              class="me-3 mt-4"
            >
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="red"
                  @click.prevent="onCancelRequest"
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-progress-circular>
              </div>
              <span v-else>{{ $t('CompanySettings.body.tab2.form.submit') }}</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              {{ $t('CompanySettings.body.tab2.form.reset') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

// Components
import CountrySelect from '@/components/CountrySelect.vue'
import RegionSelect from '@/components/RegionSelect.vue'

// Services
import useUser from '@/services/api/modules/user/user'
import useUserHandler from '@/views/apps/user/useUserHandler'

export default {
  components: {
    CountrySelect,
    RegionSelect,
  },
  props: {
    companyAddressData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { sendUserNotification } = useUserHandler()

    // To view converter .....
    const converter = companyAddressData => companyAddressData

    const companyAddressLocal = ref(JSON.parse(JSON.stringify(converter(props.companyAddressData))))
    const resetForm = () => {
      companyAddressLocal.value = JSON.parse(JSON.stringify(converter(props.companyAddressData)))
    }
    const errorMessages = ref([])

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onSubmit()
    }

    const onSubmit = () => {
      loading.value = true
      useUser
        .updateUserSocialLinks({
          whatsapp: companyAddressLocal.value.whatsapp,
          instagram: companyAddressLocal.value.instagram,
          facebook: companyAddressLocal.value.facebook,
          linkedin: companyAddressLocal.value.linkedin,
          telegram: companyAddressLocal.value.telegram,
        })
        .then(response => {
          const { user } = response.data
          if (user) {
            companyAddressLocal.value = converter(user)
          }
          sendUserNotification('Se ha cambiado correctamente', 'success')
        })
        .catch(error => {
          // debugger
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // refFormObserver.value.setErrors(error.response.data.error)
            errorMessages.value = error.response.data.error

            sendUserNotification(error.response.data.error.message, 'error')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      // Form validation
      form,
      handleFormSubmit,
      resetForm,
      required,

      // Interface events
      loading,

      // Data
      companyAddressLocal,
      errorMessages,
    }
  },
}
</script>
