<template>
  <v-card class="rounded-0 app-contact h-full position-relative overflow-hidden text-sm">
    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <ContactLeftSidebar
        :is-admin-view="isAdminView"
        :user-filter.sync="userFilter"
        :user-filter-options="userFilterOptions"
        :contact-status-filter.sync="statusFilter"
        :contact-status-filter-options="statusFilterOptions"
      ></ContactLeftSidebar>
    </v-navigation-drawer>

    <!-- Right Sidebar: Contact Profile -->
    <v-navigation-drawer
      v-model="isContactHandlerSidebarActive"
      width="374"
      :right="!$vuetify.rtl"
      :app="false"
      absolute
      touchless
      temporary
      style="z-index: 2"
    >
      <ContactSidebarContent
        v-if="contactSelected"
        v-model="isContactHandlerSidebarActive"
        :contact="contactSelected"
        :is-admin-view="isAdminView"
      ></ContactSidebarContent>
    </v-navigation-drawer>

    <!-- Delete -->
    <DialogDelete
      :id="contactDeleteId"
      v-model="isContactDeleteDialogActive"
      :title="$t('ContactList.table.dialog_delete.title')"
      :text="$t('ContactList.table.dialog_delete.text')"
      :button="$t('ContactList.table.dialog_delete.button_text')"
      @delete="onContactDelete"
    ></DialogDelete>

    <!-- Ask for Reasign Supplier -->
    <ContactReasignDialog
      v-model="isContactReasignDialogActive"
      :user-filter-options="userFilterOptions"
      @reasign="(supplier_id) => onContactReasign(supplier_id)"
    ></ContactReasignDialog>

    <!-- Content Area -->
    <div
      class="h-full position-relative"
      :style="contentStyles"
    >
      <!-- Top Actions -->
      <!-- flex-wrap: Se puede romper líneas; flex-nowrap: Misma linea -->
      <div class="d-md-flex align-center justify-space-between flex-wrap mt-4 px-2">
        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <div
            v-if="isTableVisible"
            class="d-flex align-center"
          >
            <!-- Multiple: Custom Actions -->
            <div
              v-if="selectedContacts.length"
              class="ml-2"
            >
              <!-- Reasignar contactto -->
              <v-btn
                small
                outlined
                class="me-2"
                :color="customActionReasign.color"
                @click.stop="askContactReasign()"
              >
                <v-icon size="22">
                  {{ customActionReasign.icon }}
                </v-icon>
                <span>{{ customActionReasign.title }}</span>
              </v-btn>
            </div>
          </div>

          <!-- Menu View Filter Left Side -->
          <FilterButton
            :count-filtering="countFiltering"
            :is-left-sidebar-open.sync="isLeftSidebarOpen"
            class="mr-2"
          ></FilterButton>

          <!-- SortBy and OrderBy TODO: Pasar sortBy como array (Es el elemento soportado v-data-table)-->
          <TableSortExternal
            :sort-by.sync="options.sortBy[0]"
            :sort-desc.sync="options.sortDesc[0]"
            :table-columns="tableColumns"
          ></TableSortExternal>

          <!-- Table Vs GridCard view -->
          <TableToggleView
            :is-table-visible.sync="isTableVisible"
            class="ml-2"
          ></TableToggleView>
        </div>

        <!-- Break if small -->
        <v-divider
          v-if="$vuetify.breakpoint.smAndDown"
          class="break mt-4 mb-2"
        ></v-divider>

        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <!-- search -->
          <v-text-field
            v-model="searchQueryFilter"
            :placeholder="$t('ContactList.table.search')"
            outlined
            small
            hide-details
            clearable
            class="contact-search-input"
            :prepend-inner-icon="icons.mdiMagnify"
          ></v-text-field>

          <v-btn
            icon
            color="primary"
            :outlined="false"
            small
            @click="fetchContacts"
          >
            <v-icon> {{ icons.mdiReload }}</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- Table -->
      <!-- options.sync ya resuelve todos estos parametros que cambian
        :items-per-page="options.itemsPerPage"
        :page.sync="options.page"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
      -->
      <v-data-table
        v-if="isTableVisible"
        ref="refTable"
        v-model="selectedContacts"
        :headers="tableColumns"
        :items="contacts"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        :hide-default-footer="true"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :loading="loading"
        :show-select="isAdminView"
        :single-select="false"
        mobile-breakpoint="200"
        @click:row="(item) => onHandleContactClick(item)"
      >
        <!-- User -->
        <template #[`item.userFirstName`]="{item}">
          <span>{{ item.user.firstName }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveContactStatus(item.status).color"
            :class="`${resolveContactStatus(item.status).color}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- Contact: UserContact / UserContactCompany -->
        <template #[`item.userContactCompanyName`]="{item}">
          <ContactCard :contact="item" />
        </template>

        <!-- Column: Tags -->
        <template #[`item.tags`]="{item}">
          <div class="badge-wrapper mr-1">
            <v-chip
              v-for="tag in item.tags"
              :key="tag"
              small
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ tag }}
            </v-chip>
          </div>
        </template>

        <!-- actions: EDIT & DELETE -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex flex-nowrap align-center justify-center">
            <!-- ChatMessage navegable ? -->
            <ContactChatMessageNavegable
              v-if="!isAdminView"
              :contact="item"
              :is-navegable="true"
            />

            <v-btn
              icon
              small
              @click.stop="onHandleContactClick(item)"
            >
              <v-icon>{{ icons.mdiFileEdit }}</v-icon>
            </v-btn>

            <v-btn
              class="ml-2"
              icon
              color="error"
              small
              @click.stop="askContactDelete(item.id)"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <!-- Grid System: Table Card -->
      <div v-if="!isTableVisible">
        <!-- Loading indicator -->
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
          class="position-absolute"
        />
        <v-divider></v-divider>

        <v-row
          dense
          class="row-container pt-4"
        >
          <v-col
            v-for="contact in contacts"
            :key="contact.id"
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            xl="4"
          >
            <v-hover
              #default="{ hover: isHovered }"
            >
              <div
                :class="[{'hovered elevation-3': isHovered}]"
                class="grid-box cursor-pointer"
                @click="onHandleContactClick(contact)"
              >
                <ContactGridCard
                  :contact="contact"
                  @on-action-edit="(item) => onHandleContactClick(item)"
                  @on-action-delete="(item) => askContactDelete(item.id)"
                />
              </div>
            </v-hover>
          </v-col>
        </v-row>
      </div>
      <!-- // Grid System: Table Card -->

      <!-- Footer: v-data-footer: Pagination -->
      <!-- Pagination: Usamos options porque está referenciada con v-data-table -->
      <TablePagination
        :options.sync="options"
        :items-length="serverItemsLength"
        :items-per-page-options="itemsPerPageOptions"
      ></TablePagination>
      <!-- // Footer -->
    </div>
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline, mdiFileEdit, mdiMagnify, mdiReload,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// UX
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { avatarText, filterTags, formatDateToMonthShort } from '@core/utils/filter'

// Components
// import ContactFilters from '../../ContactFilters.vue'
// import ContactHandleSidebar from './ContactHandleSidebar.vue'
import DialogDelete from '@/components/DialogDelete.vue'
import FilterButton from '@/components/FilterButton.vue'
import TablePagination from '@/components/TablePagination.vue'
import TableSortExternal from '@/components/TableSortExternal.vue'
import TableToggleView from '@/components/TableToggleView.vue'
import ContactGridCard from './ContactGridCard.vue'
import ContactLeftSidebar from './ContactLeftSidebar.vue'
import ContactReasignDialog from './ContactReasignDialog.vue'
import ContactSidebarContent from './ContactSidebarContent.vue'

import ContactCard from '@/components/Cards/ContactCard.vue'
import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'

// UI Services
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Services
import useContact from '@/services/api/modules/contact'
import useContactList from './useContactList'

// Other services
import useUserCompany from '@/services/api/modules/userCompany'

// Services
import useContactHandler from '../useContactHandler'

export default {
  components: {
    ContactLeftSidebar,
    ContactSidebarContent,
    DialogDelete,
    ContactGridCard,
    ContactReasignDialog,
    TableSortExternal,
    TableToggleView,
    TablePagination,
    FilterButton,

    ContactCard,
    ContactChatMessageNavegable,
  },
  props: {
    isAdminView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // const CONTACT_APP_STORE_MODULE_NAME = 'app-contact'
    // // Register module
    // if (!store.hasModule(CONTACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTACT_APP_STORE_MODULE_NAME, contactStoreModule)
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(CONTACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTACT_APP_STORE_MODULE_NAME)
    // })
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 })

    // Contact customization
    const {
      sendContactNotification,
      resolveContactStatus,
      contactStatusOptions,
    } = useContactHandler()

    // Status: [ 'New', 'Negociation', 'Qualified', 'Not Qualified', 'Irrelevant']
    const statusFilterOptions = contactStatusOptions

    // Admin view (Company Users)
    const userFilterOptions = ref([])
    const fetchUserCompanies = (...args) => useUserCompany.fetchUserCompanies(...args)
    if (props.isAdminView) {
      fetchUserCompanies().then(response => {
        userFilterOptions.value = response.data.userCompanies
      })
    }

    // Table (Contacts)
    const {
      // Table
      refTable,
      tableColumns,
      options,
      itemsPerPageOptions,

      loading,
      selectedContacts,
      contacts,
      serverItemsLength,

      // Fetch data
      fetchContacts,

      // Filters
      searchQueryFilter,

      // Filters: Status, Category, UserCompany
      countFiltering,
      userFilter,
      statusFilter,
    } = useContactList(props.isAdminView)

    // ————————————————————————————————————
    //* ——— Contacts Selection
    // ————————————————————————————————————
    // const selectedContacts = ref([])

    // CUSTOM ACTIONS
    const { customActionReasign } = useContactHandler()

    // Reasign with dialog form
    const isContactReasignDialogActive = ref(false)
    const askContactReasign = () => {
      isContactReasignDialogActive.value = true
    }
    const onContactReasign = (
      userId,
      contactsToReasign = selectedContacts.value,
    ) => {
      const tasks = contactsToReasign.map(contact => useContact.reasignContact(contact.id, userId))
      Promise.all(tasks).finally(() => {
        fetchContacts()
        selectedContacts.value = []

        sendContactNotification('Contact Reasigned', 'success')
      })
    }

    // Table Vs Card list
    // const isTableVisible = ref(true)
    const { isTableVisibleContact: isTableVisible } = useAppConfigUser()

    // Create Contact by Sidebar
    const isContactHandlerSidebarActive = ref(false)

    const contactSelected = ref(null)
    const clearContactData = () => {
      contactSelected.value = null
    }

    const onHandleContactClick = item => {
      // .stop prevent no funciona
      let contactData
      if (item instanceof Array) {
        // eslint-disable-next-line prefer-destructuring
        contactData = item[0]
      } else {
        contactData = item
      }

      // Checking...
      if (contactData) {
        contactSelected.value = contactData
        isContactHandlerSidebarActive.value = true
      }
    }

    // watch(isContactHandlerSidebarActive, () => {
    //   if (!isContactHandlerSidebarActive.value) {
    //     // refTable.value.clearSelected()
    //   }
    // })

    // TODO Not in use (Se añaden deltro del Handle Sidebar)
    const updateContact = contactData => {
      useContact.updateContact(contactData).then(() => {
        fetchContacts()
      })
    }

    // TODO Not in use (Se añaden deltro del Handle Sidebar)
    // Actualizamos la tabla pero seguimos con el formulario de edición abierto
    const updateContactImage = contactImage => {
      console.log(contactImage)
      fetchContacts()
    }

    // const removeContact = contactId => {
    //   useContact.removeContact(contactId)
    //     .then(() => {
    //       sendContactNotification('User Deleted', 'success')
    //       fetchContacts()
    //     })
    // }

    // Delete with dialog ask
    const isContactDeleteDialogActive = ref(false)
    const contactDeleteId = ref('')
    const askContactDelete = contactId => {
      isContactDeleteDialogActive.value = true
      contactDeleteId.value = contactId
    }

    const onContactDelete = contactId => {
      useContact
        .removeContact(contactId)
        .then(() => {
          sendContactNotification('Contact Deleted', 'success')
          fetchContacts()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            sendContactNotification(error.response.data.error.message, 'error')
          }
        })
    }

    return {
      // Table
      refTable,
      tableColumns,
      options,
      itemsPerPageOptions,

      loading,
      selectedContacts,
      contacts,
      serverItemsLength,

      // Table selection
      // selectedContacts,
      // toggleSelectedContact,
      // selectAllContactCheckbox,
      // isSelectAllContactCheckboxIndeterminate,
      // selectAllCheckboxUpdate,
      customActionReasign,
      isContactReasignDialogActive,
      askContactReasign,
      onContactReasign,

      // Table Vs Grid card
      isLeftSidebarOpen,
      isTableVisible,
      contentStyles,

      // Data
      fetchContacts,

      // Filters
      searchQueryFilter,

      // Filters(Custom): Status, UserCompany
      countFiltering,
      userFilter,
      userFilterOptions,
      statusFilter,
      statusFilterOptions,

      // CRUD Only Update
      isContactHandlerSidebarActive,
      contactSelected, // Selected item
      onHandleContactClick,
      updateContact,
      updateContactImage,
      clearContactData,

      // CRUD Delete
      isContactDeleteDialogActive,
      contactDeleteId,
      askContactDelete,
      onContactDelete,

      // UI
      filterTags,
      avatarText,
      formatDateToMonthShort,
      resolveContactStatus,

      // icons
      icons: {
        mdiMagnify,
        mdiFileEdit,
        mdiDeleteOutline,
        mdiReload,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
/* Inserting this collapsed row between two flex items will make
    * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

.grid-box {
  border-radius: 5px;
  border: 1px solid rgba(94, 86, 105, 0.14);
  // background: lightgray;
}
// ROW/COLS
.row-container {
  margin-left: 8px;
  margin-right: 8px;
}
.row-container > * {
  // max-width: 300px;
}
</style>

<style lang="scss">
.app-contact {
  // Style search input
  .contact-search-input {
    .v-input__slot {
      fieldset {
        border: none !important;
      }
    }
  }

  // Table
  .v-data-table--mobile tr:nth-child(odd) {
    border-left: 4px solid deeppink;
  }

  .v-data-table--mobile tr:nth-child(even) {
    border-left: 4px solid cyan;
  }
}

@include theme(contact-search-input) using ($material) {
  .v-input__slot {
    input {
      caret-color: map-deep-get($material, 'text', 'primary') !important;
    }
  }
}
</style>
