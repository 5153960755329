<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <company-webview-header :header-data="localOptions.header" />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <company-webview-about :about-data="localOptions.about" />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <company-webview-users :users-data="localOptions.users" />
      </v-col>
    </v-row>
  </div>
</template>

<script>

// Components (Edit and View)
import CompanyWebviewHeader from './CompanyWebviewHeader.vue'
import CompanyWebviewAbout from './CompanyWebviewAbout.vue'
import CompanyWebviewUsers from './CompanyWebviewUsers.vue'

export default {
  components: {
    CompanyWebviewHeader,
    CompanyWebviewAbout,
    CompanyWebviewUsers,
  },
  props: {
    webviewData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.webviewData)),
    }
  },
}
</script>

<style lang="scss" >
  @import '@core/preset/preset/apps/user.scss';
</style>
