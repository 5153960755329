<template>
  <v-card>
    <v-card-title class="d-flex align-center">
      <span>{{ productDemander.name }}</span>

      <v-spacer></v-spacer>
      <!-- Marketplace navegable ? (Solo para la vista NO ADMIN) -->
      <ProductDemanderMarketplaceNavegableIcon
        :product-demander="productDemander"
        :is-navegable="!isAdminView"
      />
      <!-- <ProductDemanderMarketplaceNavegableIcon :product-demander="productDemander"></ProductDemanderMarketplaceNavegableIcon> -->
      <ProductDemanderCampaignInboundNavegableIcon :product-demander="productDemander"></ProductDemanderCampaignInboundNavegableIcon>
    </v-card-title>
    <v-divider></v-divider>

    <!-- ProductDemanderCard -->
    <v-card-text>
      <ProductDemanderCard :product-demander="productDemander" />
    </v-card-text>

    <!-- Conservation / Presentation -->
    <v-card-text class="d-flex flex-row align-center">
      <span class="text-nowrap font-weight-bold">
        {{ productDemander.productConservation.name }}
      </span>
      <v-spacer></v-spacer>
      <span class="text-nowrap font-weight-bold">
        {{ productDemander.productPresentation.name }}
      </span>
    </v-card-text>

    <!-- Name & Description -->
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <div class="d-flex flex-column">
        <span>{{ productDemander.name }}</span>
        <!-- style="max-width: 200px;" -->
        <small
          class="text-truncate text-muted"
          style="height: 24px;"
        >
          {{ filterTags(productDemander.description) }}
        </small>
      </div>
    </v-card-text>

    <!-- Actions -->
    <v-divider></v-divider>
    <v-card-actions>
      <!-- Marketplace navegable ? -->
      <!-- <ProductDemanderMarketplaceNavegableIcon
        v-if="!isAdminView"
        :product-demander="productDemander"
        :is-navegable="true"
      /> -->

      <v-spacer></v-spacer>
      <v-btn
        small
        text
        color="secondary"
        @click.stop="$emit('on-action-edit', productDemander)"
      >
        <v-icon>
          {{ icons.mdiFileEdit }}
        </v-icon>
        <!-- {{ buttonTextCancel }} -->
        Editar
      </v-btn>

      <!-- @click="$emit('update:is-dialog-active',false); onDelete()" -->
      <!-- @click="$emit('update:is-dialog-active',false); $emit('delete',id)" -->
      <v-btn
        small
        text
        color="error"
        outlined
        @click.stop="$emit('on-action-delete', productDemander)"
      >
        <v-icon>
          {{ icons.mdiDelete }}
        </v-icon>
        <!-- {{ buttonText }} -->
        Eliminar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mdiDelete, mdiFileEdit } from '@mdi/js'

// UX
import { filterTags } from '@core/utils/filter'

// Components
import ProductDemanderCard from '@/components/Cards/ProductDemanderCard.vue'
import ProductDemanderCampaignInboundNavegableIcon from '@/components/NavegableIcon/ProductDemanderCampaignInboundNavegableIcon.vue'
import ProductDemanderMarketplaceNavegableIcon from '@/components/NavegableIcon/ProductDemanderMarketplaceNavegableIcon.vue'

// Services
// import useProductDemanderHandler from '../useProductDemanderHandler'

export default {
  components: {
    ProductDemanderMarketplaceNavegableIcon,
    ProductDemanderCampaignInboundNavegableIcon,
    ProductDemanderCard,
  },
  props: {
    productDemander: {
      type: Object,
      required: true,
    },
    isAdminView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // Role colors, Seller and purchaser colors
    // const { } = useProductDemanderHandler()

    return {
      // UI
      filterTags,

      // icons
      icons: {
        mdiFileEdit,
        mdiDelete,
      },
    }
  },
}
</script>
