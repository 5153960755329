<template>
  <v-tooltip
    top
    :color="productDemander.isCampaignReceiveAvailable ? 'primary' : 'grey lighten-1'"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Desactivamos cuando No sea navegable y el indicador === false -->
      <v-icon
        class="mx-2"
        :color="productDemander.isCampaignReceiveAvailable ? 'primary' : 'grey lighten-1'"
        :disabled="isNavegable && !productDemander.isMarketplaceAvailable"
        v-bind="attrs"
        v-on="on"
        @click.stop="goTo(menuItemCampaignInboundReceived.route, productDemander)"
      >
        {{ menuItemCampaignInboundReceived.icon }}
      </v-icon>
    </template>
    <!-- Campaign Receive -->
    <!-- {{ menuItemCampaignInboundReceived.title }} -->
    <span> Campaign Receive? {{ productDemander.isCampaignReceiveAvailable ? '' : '(off)' }}</span>
  </v-tooltip>
</template>
<script>

// Router
import { useRouter } from '@core/utils'
import useRouterPath from '@/router/useRouterPath'

export default {
  props: {
    productDemander: {
      type: Object,
      required: true,
    },
    isNavegable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Control de navegación
    const { menuItemCampaignInboundReceived } = useRouterPath()
    const { router } = useRouter()

    // Go to Marketplace with product preselected: Añadimos el query a la ruta
    const goTo = (toRoute, productDemander) => {
      // Exit if not navegable
      if (!props.isNavegable) {
        return
      }

      const newRoute = { ...toRoute, query: { productDemanderId: productDemander.id } }

      router.replace(newRoute)
    }

    return {
      // Router navigation
      menuItemCampaignInboundReceived,
      goTo,
    }
  },
}
</script>
