<template>
  <v-card>
    <ModalFull
      v-show="isModalFullVisible"
      :options="{ header: { title: 'Image' }}"
      @close="closeModalFull"
    >
      <template v-slot:body>
        <CropperAvatarRotateModal
          v-if="companyImageLocal"
          ref="cropperAbatarRotateModal"
          :image-data="companyImageLocal"
          cropper-style="Rectangular"
          @cancel="closeModalFull()"
          @finish="onSubmitImage($event); closeModalFull()"
        />
      </template>
    </ModalFull>

    <v-img
      class="white--text align-end"
      :src="companyHeaderLocal.image"
      :alt="companyHeaderLocal.name"
      height="250"
    />
    <v-card-text class="position-relative">
      <!-- User Avatar -->
      <v-avatar
        tile
        size="120"
        color="white"
        class="avatar-center"
      >
        <v-img
          :src="companyHeaderLocal.avatar"
          rounded
          fluid
        ></v-img>
      </v-avatar>

      <!-- Title, Subtitle & Action Button -->
      <div
        style="padding-left: 120px"
        class="d-flex justify-space-between flex-wrap pt-6"
      >
        <div class="me-2 mb-4">
          <v-card-title class="pt-0 px-0">
            {{ companyHeaderLocal.title }}
          </v-card-title>
          <v-card-subtitle class="pa-0">
            {{ companyHeaderLocal.subtitle }}
          </v-card-subtitle>
        </div>
        <!-- <v-btn
          color="primary"
          @click="showModalFull"
        >
          Upload
        </v-btn> -->

        <div class="">
          <!-- Upload -->
          <v-btn
            color="primary"
            class="me-3"
            @click="refInputImage.click()"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">{{ $t('CompanySettings.body.tab4.header.image.buttom_upload') }}</span>
          </v-btn>

          <input
            ref="refInputImage"
            type="file"
            accept=".jpeg,.png,.jpg,GIF"
            :hidden="true"
            @input="uploadImageInput($event)"
          />
          <!-- <v-file-input
            ref="refInputImage"
            accept=".jpeg,.png,.jpg,GIF"
            :hidden="true"
          ></v-file-input> -->

          <!-- Reset -->
          <v-btn
            color="error"
            outlined
            class="me-3"
            @click="onSubmitImage('')"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiDelete }}
            </v-icon>
            <span class="d-none d-sm-block">{{ $t('CompanySettings.body.tab4.header.image.buttom_reset') }}</span>
          </v-btn>
          <p class="text-sm mt-5">
            <!-- Allowed JPG, GIF or PNG. Max size of 800K -->
            {{ $t('CompanySettings.body.tab4.header.image.text') }}
          </p>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCloudUploadOutline, mdiDelete } from '@mdi/js'
import { ref } from '@vue/composition-api'

// Components
import ModalFull from '@/components/ModalFull.vue'
import CropperAvatarRotateModal from '@/components/CropperAvatar/CropperAvatarRotateModal.vue'

// Services
import useCompany from '@/services/api/modules/company/useCompany'
import useCompanyHandler from '@/views/apps/company/useCompanyHandler'

export default {
  components: {
    ModalFull,
    CropperAvatarRotateModal,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const { sendCompanyNotification } = useCompanyHandler()

    // Company data
    const companyHeaderLocal = ref(JSON.parse(JSON.stringify(props.headerData)))

    // >>> IMAGE
    const companyImageLocal = ref(null)

    // Upload Image
    const onSubmitImage = blobImage => {
      // Add the form data we need to submit
      const formData = new FormData()
      formData.append('image', blobImage)

      // Eliminamos el Blob de la variable (Despues del upload se actualiza)
      companyImageLocal.value = ''

      // Make the request to the POST /single-file URL
      useCompany.updateCompanyAccountSettingsMedia('image', formData)
        .then(response => {
          const { companyData } = response.data
          if (companyData && companyData.general) {
            companyHeaderLocal.value.image = companyData.general.image
            companyImageLocal.value = companyData.general.image
          }

          // SharedUtils.makeToast(this, 'success', 'Logo', 'Uploaded !!')
          // toast.success('Uploaded')
          sendCompanyNotification('Uploaded', 'success')
        })
        .catch(() => {
          // SharedUtils.makeToast(this, 'error', 'Logo', 'Error !!')
          // toast.error('Logo')
          sendCompanyNotification('Logo', 'error')
        })
    }

    // ModalFull control
    const isModalFullVisible = ref(false)
    const showModalFull = () => {
      isModalFullVisible.value = true
    }
    const closeModalFull = () => {
      isModalFullVisible.value = false
    }

    const refInputImage = ref(null)

    // >>> Go to Cropper
    // onClickImage => Event: Se lanza al hacer click sobre el avatar
    const onClickImage = () => {
      // Ya nos deja editar la imagen porque hemos resuelto CORS
      companyImageLocal.value = companyHeaderLocal.value.image
      showModalFull()

      // Solicitamos imagen
      // No nos deja hacer click desde el controlador => Lo ejecutamos desde el tag(Avatar or Button): @click="refInputImage.click()"
      // refInputImage.value.click()
    }

    // onSelectImageInput => Event: Se lanza cuando hemos seleccionado una imagen nueva
    const onSelectImageInput = imageRendered => {
      companyImageLocal.value = imageRendered
      showModalFull()
    }

    // @input =>> send File
    const uploadImageInput = event => {
      const file = event.srcElement.files[0] // Extract file from input event (Input Type file)

      // create a new FileReader to read this image and convert to base64 format
      const reader = new FileReader()

      // Define a callback function to run, when FileReader finishes its job
      reader.onload = e => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        // companyImageLocal.value = e.target.result
        onSelectImageInput(e.target.result)

        // Reset input: Preparamos para la siguiente
        // refInputImage.value.reset()
        refInputImage.value = null
      }

      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(file)
    }

    // <<< IMAGE

    return {
      // Image
      companyImageLocal,
      onClickImage,
      refInputImage, // ImageSelect
      onSelectImageInput,
      uploadImageInput,
      onSubmitImage,

      // Modal
      isModalFullVisible,
      showModalFull,
      closeModalFull,

      // Data
      companyHeaderLocal,

      icons: {
        mdiCloudUploadOutline,
        mdiDelete,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  // @import '~@core/preset/preset/mixins.scss';

  .avatar-center {
    top: -2rem;
    left: 1rem;
    border: 3px solid white;
    position: absolute;
  }

</style>
