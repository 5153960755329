import { mdiAlertCircle } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useProductDemanderHandler from '../useProductDemanderHandler'

// Services
import useProductDemander from '@/services/api/modules/productDemander'

export default function useProductDemanderUsersList(isAdminView = false) {
  const { sendProductDemanderNotification } = useProductDemanderHandler()

  const productDemanders = ref([])
  const refTable = ref(null)

  // Table Handlers
  // { key: 'status', sortable: true },
  // { key: 'name', sortable: true },
  // { key: 'product', label: 'CATEGORY / PRODUCT', sortable: true },
  // { key: 'productConservation', sortable: true },
  // { key: 'productPresentation', sortable: true },
  // { key: 'tags', sortable: false },
  // { key: 'actions' },

  const tableColumns = [
    {
      text: 'FLAGS', value: 'flags', align: 'center', sortable: false,
    },
    { text: 'NAME', value: 'name', sortable: true },
    { text: 'CATEGORY PRODUCT', value: 'product.name', sortable: true },
    { text: 'CONSERVATION', value: 'productConservation.name', sortable: true },
    { text: 'PRESENTATION', value: 'productPresentation.name', sortable: true },
    {
      text: 'ACTIONS', value: 'actions', align: 'center', sortable: false,
    },
  ]

  // Demander opcional (Se visualiza con Admin View)
  //    demander.user.firstName => user.firstName (Ver doctrine Order)
  if (isAdminView) {
    tableColumns.unshift({ text: 'DEMANDER', value: 'user.firstName', sortable: true })
  }

  const loading = ref(false)

  // Añadimos este atributo en options pero podría ir solo - Hay que indicarlo en el footer-props
  // ItemsPerPageOptions: [10, 25, 50, 100] - https://vuetifyjs.com/en/api/v-data-footer/#props-items-per-page-options
  // :footer-props="{
  //   'items-per-page-options': itemsPerPageOptions // [10, 25, 50, 100]
  // }"

  // DataOptions object https://vuetifyjs.com/en/api/v-data-table/#props-options
  //  page: number,
  //  itemsPerPage: number,
  //  sortBy: string[],
  //  sortDesc: boolean[],
  //  groupBy: string[],
  //  groupDesc: boolean[],
  //  multiSort: boolean,
  //  mustSort: boolean
  // El resto de los atributos los añade v-data-table al sincronizar
  const options = ref({
    // Pagination
    page: 1,
    itemsPerPage: 5,

    // Sort default: name ASC
    sortBy: ['name'],
    sortDesc: [false],
    multiSort: false,
    mustSort: false,

    // Group (Lo indicamos para que v-data-table no genere cambios sobre options y evitar que watch haga reload)
    groupBy: [],
    groupDesc: [],
  })

  const itemsPerPageOptions = ref([1, 2, 5, 10, 15, -1])
  const serverItemsLength = ref(0) // Total de resultados sin paginar
  const selectedProductDemanders = ref([])

  // Filters
  const searchQueryFilter = ref('')

  // ProductDemander Flags filters
  const isMarketplaceAvailableFilterEnabled = ref(false)
  const isMarketplaceAvailableFilter = ref(true)
  const isCampaignReceiveAvailableFilterEnabled = ref(false)
  const isCampaignReceiveAvailableFilter = ref(true)

  // Filters (Custom)
  const countFiltering = ref(0)
  const demanderFilter = ref(null)
  const productCategoryFilter = ref(null)
  const productFilter = ref(null)
  const productConservationFilter = ref(null)
  const productPresentationFilter = ref(null)

  const fetchProductDemanders = () => {
    loading.value = true
    selectedProductDemanders.value = []

    useProductDemander.fetchProductDemanders({
      q: searchQueryFilter.value,
      perPage: options.value.itemsPerPage,
      page: options.value.page,
      sortBy: options.value?.sortBy[0],
      sortDesc: options.value?.sortDesc[0],

      // ProductDemander flags filter
      isMarketplaceAvailable: isMarketplaceAvailableFilterEnabled.value ? isMarketplaceAvailableFilter.value : null,
      isCampaignReceiveAvailable: isCampaignReceiveAvailableFilterEnabled.value ? isCampaignReceiveAvailableFilter.value : null,

      // Custom filters
      isAdminView,
      demanderId: demanderFilter.value?.id,
      productCategoryId: productCategoryFilter.value?.id,
      productId: productFilter.value?.id,
      productConservationId: productConservationFilter.value?.id,
      productPresentationId: productPresentationFilter.value?.id,
    })
      .then(response => {
        const { productDemanders: list, total } = response.data

        productDemanders.value = list
        serverItemsLength.value = total
      })
      .catch(() => {
        sendProductDemanderNotification('Error fetching catalog', 'error', mdiAlertCircle)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const refetchDataByFilters = () => {
    // Cuando aplicamos filtros el resultado ya no va acorde a la página actual
    if (options.value.page !== 1) {
      options.value.page = 1 // Esto ya va a provocar un Fetch
    } else {
      fetchProductDemanders()
    }
  }

  // Paginations
  //  Page:                           Solo cambio de página
  //  ItemsPerPage, sortBy, sortDesc: Forzamos a Page 1
  watch(
    [
      () => options.value.page,
      () => options.value.itemsPerPage,
      () => options.value.sortBy[0],
      () => options.value.sortDesc[0],
    ],
    (newValue, oldValue) => {
      // Changes on: ItemsPerPage OR sortBy OR sortDesc
      if (newValue[1] !== oldValue[1] // ItemsPerPage
        || newValue[2] !== oldValue[2] // sortBy has changed
        || newValue[3] !== oldValue[3] // sortDesc has changed
      ) {
        refetchDataByFilters()
      } else {
        fetchProductDemanders()
      }
    },
  )

  // array of multiple sources: No necesitamos { deep: true }
  // watch([searchQueryFilter, Status, Category, Product, ProductConservation, ProductPresentation])
  watch(
    [
      // options,
      searchQueryFilter,
      isMarketplaceAvailableFilterEnabled,
      isMarketplaceAvailableFilter,
      isCampaignReceiveAvailableFilterEnabled,
      isCampaignReceiveAvailableFilter,
      demanderFilter,
      productCategoryFilter,
      productFilter,
      productConservationFilter,
      productPresentationFilter,
    ],
    () => {
      refetchDataByFilters()
    },
  )

  // First search
  refetchDataByFilters()

  return {
    // Table
    refTable,
    tableColumns,
    options,
    itemsPerPageOptions, // Select Item per Page

    loading,
    selectedProductDemanders,
    productDemanders, // data
    serverItemsLength,

    // Fetch data
    fetchProductDemanders,

    // Filters
    searchQueryFilter,

    // Filters: Status, Category, Product, ProductConservation, ProductPresentation
    countFiltering,
    isMarketplaceAvailableFilterEnabled,
    isMarketplaceAvailableFilter,
    isCampaignReceiveAvailableFilterEnabled,
    isCampaignReceiveAvailableFilter,
    demanderFilter,
    productCategoryFilter,
    productFilter,
    productConservationFilter,
    productPresentationFilter,
  }
}
