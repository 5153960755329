<template>
  <v-card>
    <v-card-title>
      {{ $t('CompanySettings.body.tab4.about.title') }}
    </v-card-title>

    <v-card-text>
      <div
        v-for="(data,key,index) in aboutData"
        :key="index"
        :class="index == 0 ? 'mt-2' : 'mt-1'"
      >
        <h5 class="text-capitalize mb-75">
          {{ key }}
        </h5>
        <span>{{ data }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
