// Roles de usuario
const ROLE_ADMIN = 'admin'
const ROLE_STAFF = 'staff'

// const ROLE_SELLER = 'seller'
// const ROLE_PURCHASER = 'purchaser'

// Default labels => Translated using User.Fields.company_role-options
const userRoleList = [
  { value: ROLE_ADMIN, text: 'Admin' },
  { value: ROLE_STAFF, text: 'Staff' },

  //   { value: ROLE_SELLER, label: 'Seller' },
  //   { value: ROLE_PURCHASER, label: 'Purchaser' },
]

const USER_ROLE = {
  ADMIN: ROLE_ADMIN,
  STAFF: ROLE_STAFF,

  //   SELLER: ROLE_SELLER,
  //   PURCHASER: ROLE_PURCHASER,
}

// export default { role }
export { userRoleList }
export { USER_ROLE }
