<template>
  <v-card class="rounded-0 app-product-supplier h-full position-relative overflow-hidden text-sm">
    <!-- Add or edit -->
    <ProductSupplierHandleSidebar
      v-model="isProductSupplierHandlerSidebarActive"
      :product-supplier="productSupplierSelected"
      :is-admin-view="isAdminView"
      :supplier-filter-options="supplierFilterOptions"
      :product-category-filter-options="productCategoryFilterOptions"
      :product-filter-options="productFilterOptions"
      :product-conservation-filter-options="productConservationFilterOptions"
      :product-presentation-filter-options="productPresentationFilterOptions"
      :clear-product-supplier-data="clearProductSupplierData"
      @refetch-data="fetchProductSuppliers"
    ></ProductSupplierHandleSidebar>

    <!-- Delete -->
    <DialogDelete
      :id="productSupplierDeleteId"
      v-model="isProductSupplierDeleteDialogActive"
      :dependencies-error="productSupplierDeleteDependenciesError"
      :title="$t('ProductSupplierList.table.dialog_delete.title')"
      :text="$t('ProductSupplierList.table.dialog_delete.text')"
      :button="$t('ProductSupplierList.table.dialog_delete.button_text')"
      @delete="(id) => onProductSupplierDelete(id, true)"
    ></DialogDelete>
    <!-- <DialogDeleteDependencies
      :id="productSupplierDeleteDependenciesId"
      v-model="isProductSupplierDeleteDependenciesDialogActive"
      :title="$t('ProductSupplierList.table.dialog_delete_dependencies.title')"
      :text="$t('ProductSupplierList.table.dialog_delete_dependencies.text')"
      :button="$t('ProductSupplierList.table.dialog_delete_dependencies.button_text')"
      @delete="(id) => onProductSupplierDelete(id, true)"
    ></DialogDeleteDependencies> -->

    <!-- Ask for Reasign Supplier -->
    <ProductSupplierReasignDialog
      v-model="isProductSupplierReasignDialogActive"
      :supplier-filter-options="supplierFilterOptions"
      @reasign="(supplier_id) => onProductSupplierReasign(supplier_id)"
    ></ProductSupplierReasignDialog>

    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <ProductFilters
        sm-cols="12"
        :is-admin-view="isAdminView"
        :count-filtering.sync="countFiltering"
        :supplier-filter.sync="supplierFilter"
        :supplier-filter-options="supplierFilterOptions"
        :status-filter.sync="statusFilter"
        :status-filter-options="statusFilterOptions"
        :product-category-filter.sync="productCategoryFilter"
        :product-category-filter-options="productCategoryFilterOptions"
        :product-filter.sync="productFilter"
        :product-filter-options="productFilterOptions"
        :product-conservation-filter.sync="productConservationFilter"
        :product-conservation-filter-options="productConservationFilterOptions"
        :product-presentation-filter.sync="productPresentationFilter"
        :product-presentation-filter-options="productPresentationFilterOptions"
      />
    </v-navigation-drawer>

    <!-- Content Area -->
    <div
      class="h-full position-relative"
      :style="contentStyles"
    >
      <!-- Añadido con la sidebar integrada -->
      <!-- <ProductFiltersSidebar
        v-model="isProductFiltersSidebarActive"
        :product-category-filter.sync="productCategoryFilter"
        :product-category-filter-options="productCategoryFilterOptions"
        :product-filter.sync="productFilter"
        :product-filter-options="productFilterOptions"
        :product-conservation-filter.sync="productConservationFilter"
        :product-conservation-filter-options="productConservationFilterOptions"
        :product-presentation-filter.sync="productPresentationFilter"
        :product-presentation-filter-options="productPresentationFilterOptions"
      ></ProductFiltersSidebar> -->

      <!-- Top Actions -->
      <!-- flex-wrap: Se puede romper líneas; flex-nowrap: Misma linea -->
      <div class="d-md-flex align-center justify-space-between flex-wrap mt-4 px-2">
        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <div
            v-if="isTableVisible"
            class="d-flex align-center"
          >
            <!-- <v-checkbox
              class="d-flex mt-0 pt-0"
              hide-details
              :input-value="selectAllProductSupplierCheckbox"
              :indeterminate="isSelectAllProductSupplierCheckboxIndeterminate"
              :disabled="!productSuppliers.length"
              @change="selectAllCheckboxUpdate"
            ></v-checkbox> -->

            <!-- Multiple: Custom Actions -->
            <div
              v-if="selectedProductSuppliers.length"
              class="ml-2"
            >
              <!-- Reasignar vendedor -->
              <v-btn
                small
                outlined
                class="me-2"
                :color="customActionReasign.color"
                @click.stop="askProductSupplierReasign()"
              >
                <v-icon size="22">
                  {{ customActionReasign.icon }}
                </v-icon>
                <span>{{ customActionReasign.title }}</span>
              </v-btn>
            </div>
          </div>

          <!-- Menu View Filter Left Side -->
          <FilterButton
            :count-filtering="countFiltering"
            :is-left-sidebar-open.sync="isLeftSidebarOpen"
            class="mr-2"
          ></FilterButton>

          <!-- SortBy and OrderBy TODO: Pasar sortBy como array (Es el elemento soportado v-data-table)-->
          <TableSortExternal
            :sort-by.sync="options.sortBy[0]"
            :sort-desc.sync="options.sortDesc[0]"
            :table-columns="tableColumns"
          ></TableSortExternal>

          <!-- Table Vs GridCard view -->
          <TableToggleView
            :is-table-visible.sync="isTableVisible"
            class="ml-2"
          ></TableToggleView>
        </div>

        <!-- Break if small -->
        <v-divider
          v-if="$vuetify.breakpoint.smAndDown"
          class="break mt-4 mb-2"
        ></v-divider>

        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <!-- search -->
          <v-text-field
            v-model="searchQueryFilter"
            :placeholder="$t('ProductSupplierList.table.search')"
            outlined
            small
            hide-details
            clearable
            class="product-supplier-search-input"
            :prepend-inner-icon="icons.mdiMagnify"
          ></v-text-field>

          <v-btn
            color="primary"
            outlined
            small
            @click="onAddNewProductSupplierClick"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span class="d-none d-md-block">{{ $t('ProductSupplierList.table.buttom_add') }}</span>
          </v-btn>
        </div>
      </div>

      <!-- Table -->
      <!-- options.sync ya resuelve todos estos parametros que cambian
        :items-per-page="options.itemsPerPage"
        :page.sync="options.page"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
      -->
      <v-data-table
        v-if="isTableVisible"
        ref="refTable"
        v-model="selectedProductSuppliers"
        :headers="tableColumns"
        :items="productSuppliers"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        :hide-default-footer="true"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :loading="loading"
        :show-select="isAdminView"
        :single-select="false"
        mobile-breakpoint="200"
        @click:row="(item) => onHandleProductSupplierClick(item)"
      >
        <!-- <template #[`item.data-table-select`]="{item}">
          <v-checkbox
            class="d-flex mt-0 pt-0"
            hide-details
            :input-value="selectedProductSuppliers.includes(item.id)"
            @click.stop
            @change="toggleSelectedProductSupplier(item.id)"
          ></v-checkbox>
        </template> -->

        <!-- Supplier: supplier.user -->
        <template #[`item.userFirstName`]="{item}">
          <span>{{ item.supplier.user.firstName }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveProductSupplierStatus(item.status).color"
            :class="`${resolveProductSupplierStatus(item.status).color}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- Name & Description -->
        <template #[`item.name`]="{item}">
          <div class="d-flex flex-column">
            <span>{{ item.name }}</span>
            <small
              class="text-truncate text-muted"
              style="max-width: 200px;"
            >
              {{ filterTags(item.description) }}
            </small>
          </div>
        </template>

        <!-- Product / Category-->
        <template #[`item.productName`]="{item}">
          <ProductSupplierCard :product-supplier="item" />
        </template>

        <!-- Column: ProductConservation -->
        <template #[`item.productConservationName`]="{item}">
          <span class="text-nowrap">
            {{ item.productConservation.name }}
          </span>
        </template>

        <!-- Column: productPresentation -->
        <template #[`item.productPresentationName`]="{item}">
          <span class="text-nowrap">
            {{ item.productPresentation.name }}
          </span>
        </template>

        <!-- Column: Tags -->
        <template #[`item.tags`]="{item}">
          <div class="badge-wrapper mr-1">
            <v-chip
              v-for="tag in item.tags"
              :key="tag"
              small
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ tag }}
            </v-chip>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex flex-nowrap align-center">
            <v-icon
              size="20"
              @click.stop="onHandleProductSupplierClick(item)"
            >
              {{ icons.mdiFileEdit }}
            </v-icon>
            <v-icon
              class="ml-2"
              size="20"
              color="error"
              @click.stop="askProductSupplierDelete(item.id)"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <!-- Grid System: Table Card -->
      <div v-if="!isTableVisible">
        <v-divider></v-divider>
        <v-row
          dense
          class="row-container pt-4"
        >
          <v-col
            v-for="productSupplier in productSuppliers"
            :key="productSupplier.id"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-hover
              #default="{ hover: isHovered }"
            >
              <div
                :class="[{'hovered elevation-3': isHovered}]"
                class="grid-box cursor-pointer"
                @click="onHandleProductSupplierClick(productSupplier)"
              >
                <ProductSupplierGridCard
                  :product-supplier="productSupplier"
                  @on-action-edit="(item) => onHandleProductSupplierClick(item)"
                  @on-action-delete="(item) => askProductSupplierDelete(item.id)"
                />
              </div>
            </v-hover>
          </v-col>
        </v-row>
      </div>
      <!-- // Grid System: Table Card -->

      <!-- Footer: v-data-footer: Pagination -->
      <!-- Pagination: Usamos options porque está referenciada con v-data-table -->
      <TablePagination
        :options.sync="options"
        :items-length="serverItemsLength"
        :items-per-page-options="itemsPerPageOptions"
      ></TablePagination>
      <!-- // Footer -->
    </div>
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline, mdiFileEdit, mdiMagnify, mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// UX
// import { getVuetify } from '@core/utils'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { avatarText, filterTags, formatDateToMonthShort } from '@core/utils/filter'

// Components
// import ProductFiltersSidebar from '../../ProductFiltersSidebar.vue'
// import DialogDeleteDependencies from '@/components/Dialog/DialogDeleteDependencies.vue'
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import DialogDelete from '@/components/DialogDelete.vue'
import FilterButton from '@/components/FilterButton.vue'
import TablePagination from '@/components/TablePagination.vue'
import TableSortExternal from '@/components/TableSortExternal.vue'
import TableToggleView from '@/components/TableToggleView.vue'
import ProductFilters from '../../ProductFilters.vue'
import ProductSupplierGridCard from './ProductSupplierGridCard.vue'
import ProductSupplierHandleSidebar from './ProductSupplierHandleSidebar.vue'
import ProductSupplierReasignDialog from './ProductSupplierReasignDialog.vue'

// UI Services
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Services
import useProductSupplier from '@/services/api/modules/productSupplier'
import { PRODUCT_SUPPLIER_STATUS, productSupplierStatusOptions } from '@/services/master/ProductSupplierStatus'
import useProductMasterData from '../../useProductMasterData'
import useProductSupplierList from './useProductSupplierList'

// Other services
import useSupplier from '@/services/api/modules/supplier'

// Services
import useProductSupplierHandler from '../useProductSupplierHandler'

export default {
  components: {
    ProductFilters,
    DialogDelete,
    ProductSupplierHandleSidebar,
    ProductSupplierCard,
    ProductSupplierGridCard,
    ProductSupplierReasignDialog,
    TableSortExternal,
    TableToggleView,
    TablePagination,
    FilterButton,
  },
  props: {
    isAdminView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // const PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME = 'app-product-supplier'
    // // Register module
    // if (!store.hasModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME, productSupplierStoreModule)
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME)
    // })
    // const $vuetify = getVuetify()
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 })

    // if smAndDown =>  Close Chat & Contacts left sidebar
    // const isProductFiltersSidebarActive = ref($vuetify.breakpoint.lg)
    // const isProductFiltersSidebarActive = ref(false)

    // Role colors, Seller and purchaser colors
    const { sendProductSupplierNotification, resolveProductSupplierStatus } = useProductSupplierHandler()

    // Status: [ 'Draft', 'Active', 'Inactive']
    const statusFilterOptions = productSupplierStatusOptions

    // Admin view (Company Users)
    // const fetchCompanyUsers = (...args) => useUserCompany.fetchUserCompanies(...args)
    const supplierFilterOptions = ref([])
    const fetchSuppliers = (...args) => useSupplier.fetchSuppliers(...args)
    if (props.isAdminView) {
      fetchSuppliers().then(response => {
        supplierFilterOptions.value = response.data.suppliers
      })
    }

    // Filter Options: Category, Product, ProductConservation, ProductPresentation
    const { productCategories: productCategoryFilterOptions, fetchProductCategories } = useProductMasterData()
    fetchProductCategories().then(response => {
      productCategoryFilterOptions.value = response.data.productCategories
    })

    const { products: productFilterOptions, fetchProducts } = useProductMasterData()
    fetchProducts().then(response => {
      productFilterOptions.value = response.data.products
    })

    const { productConservations: productConservationFilterOptions, fetchProductConservations } = useProductMasterData()
    fetchProductConservations().then(response => {
      productConservationFilterOptions.value = response.data.productConservations
    })

    const { productPresentations: productPresentationFilterOptions, fetchProductPresentations } = useProductMasterData()
    fetchProductPresentations().then(response => {
      productPresentationFilterOptions.value = response.data.productPresentations
    })

    // Table (ProductSuppliers)
    const {
      // Table
      refTable,
      tableColumns,
      options,
      itemsPerPageOptions,

      loading,
      selectedProductSuppliers,
      productSuppliers,
      serverItemsLength,

      // Fetch data
      fetchProductSuppliers,

      // Filters
      searchQueryFilter,

      // Filters: Status, Category, Product, ProductConservation, ProductPresentation
      countFiltering,
      supplierFilter,
      statusFilter,
      productCategoryFilter,
      productFilter,
      productConservationFilter,
      productPresentationFilter,
    } = useProductSupplierList(props.isAdminView)

    // ————————————————————————————————————
    //* ——— ProductSuppliers Selection
    // ————————————————————————————————————
    // const selectedProductSuppliers = ref([])
    // const toggleSelectedProductSupplier = productSupplierId => {
    //   const productSupplierIndex = selectedProductSuppliers.value.indexOf(productSupplierId)
    //   if (productSupplierIndex === -1) selectedProductSuppliers.value.push(productSupplierId)
    //   else selectedProductSuppliers.value.splice(productSupplierIndex, 1)
    // }
    // const selectAllProductSupplierCheckbox = computed(
    //   () => productSuppliers.value.length && productSuppliers.value.length === selectedProductSuppliers.value.length,
    // )
    // const isSelectAllProductSupplierCheckboxIndeterminate = computed(
    //   () => Boolean(selectedProductSuppliers.value.length) && productSuppliers.value.length !== selectedProductSuppliers.value.length,
    // )
    // const selectAllCheckboxUpdate = val => {
    //   selectedProductSuppliers.value = val ? productSuppliers.value.map(productSupplier => productSupplier.id) : []
    // }

    // CUSTOM ACTIONS
    const { customActionReasign } = useProductSupplierHandler()

    // Reasign with dialog form
    const isProductSupplierReasignDialogActive = ref(false)
    const askProductSupplierReasign = () => {
      isProductSupplierReasignDialogActive.value = true
    }
    const onProductSupplierReasign = (
      supplierId,
      productSuppliersToReasign = selectedProductSuppliers.value,
    ) => {
      const tasks = productSuppliersToReasign.map(productSupplier => useProductSupplier.reasignProductSupplier(productSupplier.id, supplierId))
      Promise.all(tasks).finally(() => {
        fetchProductSuppliers()
        selectedProductSuppliers.value = []

        sendProductSupplierNotification('Product Reasigned', 'success')
      })
    }

    // Table Vs Card list
    // const isTableVisible = ref(true)
    const { isTableVisibleProductSupplier: isTableVisible } = useAppConfigUser()

    // Create ProductSupplier by Sidebar
    const isProductSupplierHandlerSidebarActive = ref(false)
    const blankProductSupplier = {
      id: null,
      status: PRODUCT_SUPPLIER_STATUS.DRAFT,

      name: '',
      description: '',
      image: '', // require('@/assets/images/pages/eCommerce/27.png'),
      tags: [],
      createdAt: new Date(),

      // Object complete
      supplier: null,
      product: null,
      productConservation: null,
      productPresentation: null,
    }

    const productSupplierSelected = ref(JSON.parse(JSON.stringify(blankProductSupplier)))
    const clearProductSupplierData = () => {
      productSupplierSelected.value = JSON.parse(JSON.stringify(blankProductSupplier))
    }

    const onAddNewProductSupplierClick = () => {
      productSupplierSelected.value = blankProductSupplier
      isProductSupplierHandlerSidebarActive.value = true
    }

    const onHandleProductSupplierClick = item => {
      // .stop prevent no funciona
      let productSupplierData
      if (item instanceof Array) {
        // eslint-disable-next-line prefer-destructuring
        productSupplierData = item[0]
      } else {
        productSupplierData = item
      }

      // Checking...
      if (productSupplierData) {
        productSupplierSelected.value = { ...blankProductSupplier, ...productSupplierData }

        // productSupplier.value = _.merge({}, blankProductSupplier, productSupplierData)
        isProductSupplierHandlerSidebarActive.value = true
      }
    }

    // watch(isProductSupplierHandlerSidebarActive, () => {
    //   if (!isProductSupplierHandlerSidebarActive.value) {
    //     // refTable.value.clearSelected()
    //   }
    // })

    // TODO Not in use (Se añaden deltro del Handle Sidebar)
    const addProductSupplier = productSupplierData => {
      useProductSupplier.createProductSupplier(productSupplierData).then(() => {
        fetchProductSuppliers()
      })
    }

    // TODO Not in use (Se añaden deltro del Handle Sidebar)
    const updateProductSupplier = productSupplierData => {
      useProductSupplier.updateProductSupplier(productSupplierData).then(() => {
        fetchProductSuppliers()
      })
    }

    // TODO Not in use (Se añaden deltro del Handle Sidebar)
    // Actualizamos la tabla pero seguimos con el formulario de edición abierto
    const updateProductSupplierImage = productSupplierImage => {
      console.log(productSupplierImage)
      fetchProductSuppliers()
    }

    // Delete with dialog ask
    const isProductSupplierDeleteDialogActive = ref(false)
    const productSupplierDeleteId = ref('')
    const productSupplierDeleteDependenciesError = ref(null)
    const askProductSupplierDelete = (productSupplierId, dempendenciesError = null) => {
      isProductSupplierDeleteDialogActive.value = true
      productSupplierDeleteId.value = productSupplierId
      productSupplierDeleteDependenciesError.value = dempendenciesError
    }

    // Delete dependencies with dialog ask
    // const isProductSupplierDeleteDependenciesDialogActive = ref(false)
    // const productSupplierDeleteDependenciesId = ref('')
    // const askProductSupplierDeleteDependencies = (productSupplierId, dempendenciesError) => {
    //   isProductSupplierDeleteDependenciesDialogActive.value = true
    //   productSupplierDeleteDependenciesId.value = productSupplierId
    //   productSupplierDeleteDependenciesError.value = dempendenciesError
    // }

    const onProductSupplierDelete = (productSupplierId, force = false) => {
      console.log(force)
      useProductSupplier
        .removeProductSupplier(productSupplierId, props.isAdminView)
        .then(() => {
          sendProductSupplierNotification('Product Deleted', 'success')
          fetchProductSuppliers()
        })
        .catch(error => {
          // Checking... Dependencies error
          // if (error.response && error.response.data && error.response.data.error) {
          if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.code === 'product_supplier_dependencies_error') {
              // if (error.response.data.error.campaigns || error.response.data.error.contactRequests) {
              // Send dependencies
              //  campaigns: error.response.data.error.campaigns,
              //  contactRequestInbounds: error.response.data.error.contactRequestInbounds,
              askProductSupplierDelete(productSupplierId, error.response.data.error)
            } else {
              sendProductSupplierNotification(error.response.data.message, 'error')
            }
          }
        })
    }

    return {
      // Table
      refTable,
      tableColumns,
      options,
      itemsPerPageOptions,

      loading,
      selectedProductSuppliers,
      productSuppliers,
      serverItemsLength,

      // Table selection
      // selectedProductSuppliers,
      // toggleSelectedProductSupplier,
      // selectAllProductSupplierCheckbox,
      // isSelectAllProductSupplierCheckboxIndeterminate,
      // selectAllCheckboxUpdate,
      customActionReasign,
      isProductSupplierReasignDialogActive,
      askProductSupplierReasign,
      onProductSupplierReasign,

      // Table Vs Grid card
      isLeftSidebarOpen,
      isTableVisible,
      contentStyles,

      // Data
      fetchProductSuppliers,

      // Filters
      searchQueryFilter,

      // Filters(Custom): Status, Category, Product, ProductConservation, ProductPresentation
      countFiltering,
      supplierFilter,
      supplierFilterOptions,
      statusFilter,
      statusFilterOptions,
      productFilter,
      productFilterOptions,
      productCategoryFilter,
      productCategoryFilterOptions,
      productConservationFilter,
      productConservationFilterOptions,
      productPresentationFilter,
      productPresentationFilterOptions,

      // CRUD Insert and Update
      isProductSupplierHandlerSidebarActive,
      productSupplierSelected, // Selected item
      onAddNewProductSupplierClick,
      onHandleProductSupplierClick,
      addProductSupplier,
      updateProductSupplier,
      updateProductSupplierImage,
      clearProductSupplierData,

      // CRUD Delete
      isProductSupplierDeleteDialogActive,
      productSupplierDeleteId,
      productSupplierDeleteDependenciesError,
      askProductSupplierDelete,
      onProductSupplierDelete,

      // Delete with dependencies
      // isProductSupplierDeleteDependenciesDialogActive,
      // productSupplierDeleteDependenciesId,

      // UI
      filterTags,
      avatarText,
      formatDateToMonthShort,
      resolveProductSupplierStatus,

      // icons
      icons: {
        mdiMagnify,
        mdiFileEdit,
        mdiDeleteOutline,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
/* Inserting this collapsed row between two flex items will make
  * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

.grid-box {
  border-radius: 5px;
  border: 1px solid rgba(94, 86, 105, 0.14);
  // background: lightgray;
}
// ROW/COLS
.row-container {
  margin-left: 8px;
  margin-right: 8px;
}
.row-container > * {
  // max-width: 300px;
}
</style>

<style lang="scss">
.app-product-supplier {
  // Style search input
  .product-supplier-search-input {
    .v-input__slot {
      fieldset {
        border: none !important;
      }
    }
  }

  // Table
  .v-data-table--mobile tr:nth-child(odd) {
    border-left: 4px solid deeppink;
  }

  .v-data-table--mobile tr:nth-child(even) {
    border-left: 4px solid cyan;
  }
}

@include theme(product-supplier-search-input) using ($material) {
  .v-input__slot {
    input {
      caret-color: map-deep-get($material, 'text', 'primary') !important;
    }
  }
}
</style>
