<template>
  <!-- <transition name="slide-up"> -->
  <transition name="slide">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header">
            <!-- Header -->
            <div
              class="w-full d-flex justify-space-between align-center pl-2 py-1"
            >
              <!-- Left: Back + Title -->
              <div
                class="d-flex justify-space-between align-center cursor-pointer"
                @click="onClose"
              >
                <span class="mr-1">
                  <!-- :icon="$store.state.appConfig.isRTL ? 'ArrowRightIcon' : 'ArrowLeftIcon'" -->
                  <v-icon size="24">
                    {{ icons.mdiChevronLeft }}
                  </v-icon>
                </span>
                <h4 class="mb-0">
                  {{ header.title }}
                </h4>
              </div>
              <!-- Right: Close -->
              <div class="ml-2 pl-1">
                <v-icon
                  size="24"
                  class="cursor-pointer"
                  @click="onClose"
                >
                  {{ icons.mdiClose }}
                </v-icon>
              </div>
            </div>
          </slot>
        </header>

        <section class="modal-body">
          <slot name="body">
            This is the default body!
          </slot>
        </section>

        <footer
          v-if="footer"
          class="modal-footer"
        >
          <slot name="footer">
            This is the default footer!
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

// import {
//   BFormFile, BButton,
// } from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import { mdiChevronLeft, mdiClose } from '@mdi/js'

export default {
  name: 'Modal',
  props: {
    options: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const onClose = () => {
      emit('close')
    }
    const header = ref({
      title: props.options?.header?.title || 'Title',
    })
    const footer = ref(props.options?.footer)

    return {
      onClose,
      header,
      footer,

      icons: {
        mdiChevronLeft,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .modal-backdrop {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    // background-color: unset;
    opacity: 1;
    // Flex
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    // padding: 20px 10px;
    padding: 0;
  }

    // Transition Slide:
    //  See: https://stackoverflow.com/questions/54260310/vue-enter-transition-not-working-properly
    /*
        Enter and leave animations can use different durations and timing functions.
        Enter action not don't work (Comment)
    */
    .slide-enter-active {
        transition: all 0.3s ease-out;
    }
    .slide-leave-active {
        transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
    }
    // Para esta transformacion no funciona el .slide-enter-from pero si .slide-enter
    // .slide-enter-from, .slide-leave-to // No hace nada
    .slide-enter, .slide-leave-to {
        transform: translateY(100vh);
        opacity: 0;
    }

</style>
