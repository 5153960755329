<template>
  <v-card>
    <v-card-title class="d-flex">
      <span>{{ contact.contact.name }}</span>
      <v-chip
        small
        :color="resolveContactStatus(contact.status).color"
        :class="`${resolveContactStatus(contact.status).color}--text`"
        class="v-chip-light-bg font-weight-semibold text-capitalize text-right"
      >
        {{ contact.status }}
      </v-chip>
      <v-spacer></v-spacer>
      <ContactChatMessageNavegable
        v-if="!isAdminView"
        :contact="contact"
        :is-navegable="true"
      ></ContactChatMessageNavegable>
      <v-btn
        icon
        color="primary"
        text
        outlined
        @click.stop="$emit('on-action-edit', contact)"
      >
        <v-icon>
          {{ icons.mdiFileEdit }}
        </v-icon>
      </v-btn>

      <v-btn
        icon
        color="error"
        outlined
        text
        class="ml-2"
        @click.stop="$emit('on-action-delete', contact)"
      >
        <v-icon>
          {{ icons.mdiDelete }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <!-- ContactCard + Tags (Overflow auto) -->
    <!-- style="height: 64px;" -->
    <div
      class="d-flex align-center justify-space-between pa-4"
    >
      <ContactCard :contact="contact" />

      <div class="h-full overflow-auto">
        <v-chip
          v-for="tag in contact.tags"
          :key="tag"
          small
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ tag }}
        </v-chip>
      </div>
    </div>
    <!-- Actions -->
    <!-- <v-divider></v-divider> -->
    <!-- <v-card-actions>
      <ContactChatMessageNavegable
        v-if="!isAdminView"
        :contact="contact"
        :is-navegable="true"
      ></ContactChatMessageNavegable>

      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        text
        @click.stop="$emit('on-action-edit', contact)"
      >
        <v-icon>
          {{ icons.mdiFileEdit }}
        </v-icon>
        Editar
      </v-btn>

      <v-btn
        color="error"
        outlined
        text
        @click.stop="$emit('on-action-delete', contact)"
      >
        <v-icon>
          {{ icons.mdiDelete }}
        </v-icon>
        Eliminar
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
<script>
import { mdiDelete, mdiFileEdit } from '@mdi/js'

// UX
import { filterTags } from '@core/utils/filter'

// Components
import ContactCard from '@/components/Cards/ContactCard.vue'
import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'

// Services
import useContactHandler from '../useContactHandler'

export default {
  components: {
    ContactCard,
    ContactChatMessageNavegable,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    isAdminView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // Role colors, Seller and purchaser colors
    const { resolveContactStatus } = useContactHandler()

    return {
      // UI
      filterTags,
      resolveContactStatus,

      // icons
      icons: {
        mdiFileEdit,
        mdiDelete,
      },
    }
  },
}
</script>
