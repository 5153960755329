<template>
  <div>
    <example-wrapper
      class="rotate-image-example"
    >
      <!-- @change="onChange" -->
      <cropper
        v-if="cropperCircular"
        ref="refCropper"
        :src="imageLocal"
        :transitions="true"
        default-boundaries="fill"
        image-restriction="fit-area"
        class="circle-cropper"
        stencil-component="circle-stencil"
        :stencil-props="{
          previewClass: 'circle-cropper__preview',
        }"
      />
      <cropper
        v-else
        ref="refCropper"
        :src="imageLocal"
        :transitions="true"
        default-boundaries="fill"
        image-restriction="fit-area"
        class="rectangular-cropper"
        :stencil-props="{
          aspectRatio: 3/1,
        }"
      />

      <!-- Central Buttons: Open file when empty -->
      <!-- <div
        v-if="!imageLocal"
        class="central-buttons"
      >
        <square-button
          title="Upload"
          @click="refInputImage.$el.click()"
        >
          <feather-icon
            icon="FolderIcon"
            size="64"
          />
        </square-button>
      </div> -->

      <!-- Left Buttons -->
      <vertical-buttons-left>
        <square-button
          title="Resize (x2)"
          @click="resize(2, 2)"
        >
          <img :src="require('./assets/icons/resize.svg')">
        </square-button>
        <square-button
          title="Resize (x1/2)"
          @click="resize(0.5, 0.5)"
        >
          <img :src="require('./assets/icons/resize-reduce.svg')">
        </square-button>
        <square-button
          title="Maximize"
          @click="maximize()"
        >
          <img :src="require('./assets/icons/resize-maximize.svg')">
        </square-button>
        <square-button
          title="Flip Horizontal"
          @click="flip(true, false)"
        >
          <img :src="require('./assets/icons/flip-horizontal.svg')">
        </square-button>
        <square-button
          title="Flip Vertical"
          @click="flip(false, true)"
        >
          <img :src="require('./assets/icons/flip-vertical.svg')">
        </square-button>
        <square-button
          title="Rotate Clockwise"
          @click="rotate(90)"
        >
          <img :src="require('./assets/icons/rotate-clockwise.svg')">
        </square-button>
        <square-button
          title="Rotate Counter-Clockwise"
          @click="rotate(-90)"
        >
          <img :src="require('./assets/icons/rotate-counter-clockwise.svg')">
        </square-button>
      </vertical-buttons-left>

      <!-- Right buttons -->
      <vertical-buttons-right>
        <square-button
          title="Save"
          @click="onFinish()"
        >
          <img :src="require('./assets/icons/download.svg')">
        </square-button>

        <!-- <img :src="require('./assets/icons/arrow-bottom.svg')"> -->
        <square-button
          title="Open"
          @click="openImageInNewTab()"
        >
          <!-- <feather-icon
            style="color: white;"
            icon="DownloadIcon"
            size="16"
          /> -->
          <v-icon
            style="color: white;"
            size="16"
          >
            {{ icons.mdiDownload }}
          </v-icon>
        </square-button>
      </vertical-buttons-right>
    </example-wrapper>
  </div>
</template>

<script>
import { mdiDownload } from '@mdi/js'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

// Upload utils
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, watch } from '@vue/composition-api'

// Components
import ExampleWrapper from './components/ExampleWrapper.vue'
import VerticalButtonsLeft from './components/VerticalButtonsLeft.vue'
import VerticalButtonsRight from './components/VerticalButtonsRight.vue'
import SquareButton from './components/SquareButton.vue'

export default {
  components: {

    Cropper,
    ExampleWrapper,
    VerticalButtonsLeft,
    VerticalButtonsRight,
    SquareButton,
  },
  props: {
    imageData: {
      type: String,

      //   default: '',
      default: 'https://images.unsplash.com/photo-1600353068867-5b4de71e3afb?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80',
    },
    cropperStyle: {
      type: String,
      default: 'Circular',
    },
    localData: {
      type: Object,
      default: () => ({ avatar: '' }),
    },
  },
  setup(props, { emit }) {
    // const convertImgUrlToBase64 = url => {
    //   debugger
    //   let canvas = document.createElement('CANVAS')
    //   const img = document.createElement('img')
    //   img.src = url
    //   img.onload = () => {
    //     canvas.height = img.height
    //     canvas.width = img.width

    //     const result = canvas.toDataURL('image/png')
    //     // eslint-disable-next-line no-use-before-define
    //     imageLocal.value = result
    //     canvas = null
    //   }
    //   img.onerror = error => {
    //     console.log(error)
    //     console.log('Could not load image, please check that the file is accessible and an image!')
    //   }
    // }
    // const convertImgUrlToBase64 = url => {
    //   const image = new Image()
    //   image.setAttribute('crossOrigin', 'anonymous') // use it if you try in a different origin of your web
    //   image.src = url
    //   image.onload = () => {
    //     const canvas = document.createElement('canvas')
    //     canvas.width = image.width
    //     canvas.height = image.height

    //     canvas.getContext('2d').drawImage(this, 0, 0)
    //     canvas.toBlob(
    //       source => {
    //         const newImg = document.createElement('img')
    //         const url2 = URL.createObjectURL(source)

    //         newImg.onload = () => {
    //           URL.revokeObjectURL(url2) // no longer need to read the blob so it's revoked
    //         }
    //         newImg.src = url
    //       },
    //       'image/jpeg',
    //       1,
    //     )

    //     // If you ever stumble at 18 DOM Exception, just use this code to fix it
    //     // let dataUrl = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
    //     const dataUrl = canvas.toDataURL('image/jpeg')
    //     debugger
    //     // eslint-disable-next-line no-use-before-define
    //     imageLocal.value = dataUrl
    //   }
    // }

    // Refs
    const refCropper = ref(null) // Cropper
    const cropperCircular = ref(props.cropperStyle === 'Circular')

    const imageLocal = ref(props.imageData)
    watch(() => props.imageData, () => {
      imageLocal.value = props.imageData

    //   convertImgUrlToBase64(props.imageData)
    })

    // Methods
    // Open image on a new tab
    const openImageInNewTab = () => {
      const { canvas } = refCropper.value.getResult()
      const newTab = window.open()
      newTab.document.body.innerHTML = `<img src="${canvas.toDataURL()}"></img>`
    }
    const onBlobConverter = blob => {
      emit('finish', blob)
    }
    const onFinish = () => {
      const { canvas } = refCropper.value.getResult()
      if (canvas) {
        // canvas.toBlob(blob => emit('input', blob), 'image/jpeg', 0.5)
        if (cropperCircular.value) {
          canvas.toBlob(onBlobConverter)
        } else {
          canvas.toBlob(onBlobConverter, 'image/jpeg', 0.5)
        }
      }
    }

    // Not in use
    const onFinishBase64 = () => {
      // const result = refcropper.value.getResult().canvas.toDataURL()
      let result = null

      if (cropperCircular.value) {
        result = refCropper.value.getResult().canvas.toDataURL()
      } else {
        result = refCropper.value.getResult().canvas.toDataURL('image/jpeg', 0.5) // TODO
      }

      //   const result = URL.createObjectURL(base64)
      //   emit('input', result)
      emit('finish', result)
    }

    // <<<

    const onCancel = () => {
      emit('cancel')
    }

    const flip = (x, y) => {
      if (refCropper.value.customImageTransforms.rotate % 180 !== 0) {
        refCropper.value.flip(!x, !y)
      } else {
        refCropper.value.flip(x, y)
      }
    }
    const rotate = angle => {
      refCropper.value.rotate(angle)
    }
    const resize = (width = 1, height = 1) => {
      let startCoordinates
      refCropper.value.setCoordinates([
        // eslint-disable-next-line no-unused-vars
        ({ coordinates, imageSize }) => {
          startCoordinates = coordinates

          return {
            width: coordinates.width * width,
            height: coordinates.height * height,
          }
        },
        // eslint-disable-next-line no-unused-vars
        ({ coordinates, imageSize }) => ({
          left: startCoordinates.left + (startCoordinates.width - coordinates.width) / 2,
          top: startCoordinates.top + (startCoordinates.height - coordinates.height) / 2,
        }),
      ])
    }
    const center = () => {
      refCropper.value.setCoordinates(({ coordinates, imageSize }) => ({
        left: imageSize.width / 2 - coordinates.width / 2,
        top: imageSize.height / 2 - coordinates.height / 2,
      }))
    }
    const maximize = () => {
      // const { cropper } = this.$refs
      // const cropper = refCropper.value
      const centerImage = {
        left: refCropper.value.coordinates.left + refCropper.value.coordinates.width / 2,
        top: refCropper.value.coordinates.top + refCropper.value.coordinates.height / 2,
      }
      refCropper.value.setCoordinates([
        // eslint-disable-next-line no-unused-vars
        ({ coordinates, imageSize }) => ({
          width: imageSize.width,
          height: imageSize.height,
        }),
        // eslint-disable-next-line no-unused-vars
        ({ coordinates, imageSize }) => ({
          left: centerImage.left - coordinates.width / 2,
          top: centerImage.top - coordinates.height / 2,
        }),
      ])
    }

    return {
      // Refs
      refCropper,

      // Data
      imageLocal,
      cropperCircular,

      openImageInNewTab,
      onFinish,
      onBlobConverter,
      onFinishBase64,
      onCancel,

      // Crop methods
      flip,
      rotate,
      resize,
      center,
      maximize,

      icons: {
        mdiDownload,
      },
    }
  },
}
</script>

<style lang="scss">
    .rotate-image-example {
        .cropper {
            // max-height: 500px;
            height: 512px;
        }
        .circle-cropper {
            height: 512px;
            width: 100%;

            // Resaltamos lo seleccionado (Esto no lo está cogiendo)
            // background: #222;
            &__preview {
                border: solid 1px rgba(white, 0.15);
            }
            // vue-advanced-cropper__image-wrapper
        }

        // Botones centrales
        .central-buttons {
            position: absolute;
            left: calc(50% - 125px); // Restamos la mitad del ancho para centrar
            top: 50%;
            transform: translateY(-50%);

            .square-button {
                background: rgba(black, 0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 250px;
                width: 250px;
                margin-bottom: 10px;
                cursor: pointer;
                transition: background 0.5s;
                &:hover {
                    background: black;
                }
            }
        }
    }
    // Camboiamos el estandar
    .vue-advanced-cropper__background {
        background: #333;
    }
</style>
