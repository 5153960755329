import { ref, watch } from '@vue/composition-api'
import { mdiAlertCircle } from '@mdi/js'

// Services
import useProductSupplierHandler from '../useProductSupplierHandler'

// Services
import useProductSupplier from '@/services/api/modules/productSupplier'

export default function useProductSupplierUsersList(isAdminView = false) {
  const { sendProductSupplierNotification } = useProductSupplierHandler()

  const productSuppliers = ref([])
  const refTable = ref(null)

  // Table Handlers
  // { key: 'status', sortable: true },
  // { key: 'name', sortable: true },
  // { key: 'product', label: 'CATEGORY / PRODUCT', sortable: true },
  // { key: 'productConservation', sortable: true },
  // { key: 'productPresentation', sortable: true },
  // { key: 'tags', sortable: false },
  // { key: 'actions' },

  // TODO - Analizar si justamos CONSERVATION Y PRESENTATION
  // value representa:
  //    El valor de ordenación en el servidor: sortBy: ['product.name']
  //    El nombre de la columna: <template #[`item.product.name`]="{item}">
  //
  // Doctrine conversions
  //    'userFirstName'           => 'user.firstName',
  //    'productName'             => 'product.name',
  //    'productConservationName' => 'productConservation.name',
  //    'productPresentationName' => 'productPresentation.name'
  const tableColumns = [
    // { text: 'Supplier', value: 'supplier', sortable: true },
    { text: 'STATUS', value: 'status', sortable: true },
    { text: 'NAME', value: 'name', sortable: true },
    { text: 'CATEGORY PRODUCT', value: 'productName', sortable: true },
    { text: 'CONSERVATION', value: 'productConservationName', sortable: true },
    { text: 'PRESENTATION', value: 'productPresentationName', sortable: true },
    { text: 'TAGS', value: 'tags', sortable: false },
    {
      text: 'ACTIONS', value: 'actions', align: 'center', sortable: false,
    },
  ]

  // Supplier opcional (Se visualiza con Admin View)
  //    supplier.user.firstName => user.firstName (Ver doctrine Order)
  if (isAdminView) {
    tableColumns.unshift({ text: 'SUPPLIER', value: 'userFirstName', sortable: true })
  }

  const loading = ref(false)

  // Añadimos este atributo en options pero podría ir solo - Hay que indicarlo en el footer-props
  // ItemsPerPageOptions: [10, 25, 50, 100] - https://vuetifyjs.com/en/api/v-data-footer/#props-items-per-page-options
  // :footer-props="{
  //   'items-per-page-options': options.itemsPerPageOptions // [10, 25, 50, 100]
  // }"

  // DataOptions object https://vuetifyjs.com/en/api/v-data-table/#props-options
  //  page: number,
  //  itemsPerPage: number,
  //  sortBy: string[],
  //  sortDesc: boolean[],
  //  groupBy: string[],
  //  groupDesc: boolean[],
  //  multiSort: boolean,
  //  mustSort: boolean
  // El resto de los atributos los añade v-data-table al sincronizar
  const options = ref({
    // Pagination
    page: 1,
    itemsPerPage: 5,

    // Sort default: name ASC
    sortBy: ['name'],
    sortDesc: [false],
    multiSort: false,
    mustSort: false,

    // Group (Lo indicamos para que v-data-table no genere cambios sobre options y evitar que watch haga reload)
    groupBy: [],
    groupDesc: [],
  })

  const itemsPerPageOptions = ref([1, 2, 5, 10, 15, -1])
  const serverItemsLength = ref(0) // Total de resultados sin paginar
  const selectedProductSuppliers = ref([])

  // Filters
  const searchQueryFilter = ref('')

  // Filters (Custom)
  const countFiltering = ref(0)
  const supplierFilter = ref(null)
  const statusFilter = ref(null) // object(value: String, text: String) (ProductDemanderStatusList, ProductSupplierStatusList)
  const productCategoryFilter = ref(null)
  const productFilter = ref(null)
  const productConservationFilter = ref(null)
  const productPresentationFilter = ref(null)

  const fetchProductSuppliers = () => {
    loading.value = true
    selectedProductSuppliers.value = []

    useProductSupplier.fetchProductSuppliers({
      q: searchQueryFilter.value,
      perPage: options.value.itemsPerPage,
      page: options.value.page,
      sortBy: options.value?.sortBy[0],
      sortDesc: options.value?.sortDesc[0],

      // Custom filters
      isAdminView,
      supplierId: supplierFilter.value?.id,
      status: statusFilter.value?.value,
      productCategoryId: productCategoryFilter.value?.id,
      productId: productFilter.value?.id,
      productConservationId: productConservationFilter.value?.id,
      productPresentationId: productPresentationFilter.value?.id,
    })
      .then(response => {
        const { productSuppliers: list, total } = response.data

        productSuppliers.value = list
        serverItemsLength.value = total
      })
      .catch(() => {
        sendProductSupplierNotification('Error fetching catalog', 'error', mdiAlertCircle)
      })
      .finally(() => { loading.value = false })
  }

  const refetchDataByFilters = () => {
    // Cuando aplicamos filtros el resultado ya no va acorde a la página actual
    if (options.value.page !== 1) {
      options.value.page = 1 // Esto ya va a provocar un Fetch
    } else {
      fetchProductSuppliers()
    }
  }

  // Paginations
  //  Page:                           Solo cambio de página
  //  ItemsPerPage, sortBy, sortDesc: Forzamos a Page 1
  watch(
    [
      () => options.value.page,
      () => options.value.itemsPerPage,
      () => options.value.sortBy[0],
      () => options.value.sortDesc[0],
    ],
    (newValue, oldValue) => {
      // Changes on: ItemsPerPage OR sortBy OR sortDesc
      if (newValue[1] !== oldValue[1] // ItemsPerPage
        || newValue[2] !== oldValue[2] // sortBy has changed
        || newValue[3] !== oldValue[3] // sortDesc has changed
      ) {
        refetchDataByFilters()
      } else {
        fetchProductSuppliers()
      }
    },
  )

  // array of multiple sources: No necesitamos { deep: true }
  // watch([searchQueryFilter, Status, Category, Product, ProductConservation, ProductPresentation])
  watch(
    [
      // options,
      searchQueryFilter,
      supplierFilter,
      statusFilter,
      productCategoryFilter,
      productFilter,
      productConservationFilter,
      productPresentationFilter,
    ],
    () => {
      refetchDataByFilters()
    },
  )

  // First search
  refetchDataByFilters()

  return {
    // Table
    refTable,
    tableColumns,
    options,
    itemsPerPageOptions, // Select Item per Page

    loading,
    selectedProductSuppliers,
    productSuppliers, // data
    serverItemsLength,

    // Fetch data
    fetchProductSuppliers,

    // Filters
    searchQueryFilter,

    // Filters(Custom): Status, Category, Product, ProductConservation, ProductPresentation
    countFiltering,
    supplierFilter,
    statusFilter,
    productCategoryFilter,
    productFilter,
    productConservationFilter,
    productPresentationFilter,
  }
}
