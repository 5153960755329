import axios from '@axios'

// import BaseApiService from '../apiBase'

// interface DemanderInterface {
//     id: string;
//     user: User;
//     company: Company;
// }

// # Demander Router list
// # GET    /demanders             Retrieves the collection of Demander resources.
// # POST   /demanders             Creates a Demander resource.
// # GET    /demanders/{id}        Retrieves a Demander resource.
// # PUT    /demanders/{id}        Replaces a Demander resource.
// # DELETE /demanders/{id}        Removes a Demander resource.
const routeDemanders = '/demanders'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// Si extendemos duplicamos instancias axios
// class Demander extends BaseApiService { constructor() { super(axios) }
class Demander {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchDemanders(queryParams = {}) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeDemanders}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // createDemander(demanderData) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .post(`${this.API_BASE}${routeDemanders}`, demanderData)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  // fetchDemander(id) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .get(`${this.API_BASE}${routeDemanders}/${id}`)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  // removeDemander(id) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .delete(`${this.API_BASE}${routeDemanders}/${id}`)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  // updateDemander(demanderData) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .put(`${this.API_BASE}${routeDemanders}/${demanderData.id}`, { ...demanderData })
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }
}

// Exportamos useDemander (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useDemander = new Demander()
export default useDemander
