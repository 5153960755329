<template>
  <!--
      { name: 'Badakhshan', shortCode: 'BDS' }
   -->
  <!-- <v-select
    :id="id"
    v-model="localValue"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :options="regionOptions"
    label="name"
    :reduce="region => region.shortCode"
    :placeholder="placeholder"
    :class="className"
    :autocomplete="autocompleteAttr"
    @input="onChange"
  /> -->
  <v-select
    v-model="localValue"
    :items="regionOptions"
    outlined
    item-text="name"
    item-value="shortCode"
    :label="placeholder"
    hide-details="auto"
    :error-messages="errorMessage"
    :rules="rules"
    @input="onChange"
  ></v-select>
</template>

<script>

// { name: 'Badakhshan', shortCode: 'BDS' }
// import regions from '@/data.js'
import regions from '@/services/master/country-region'

export default {
  name: 'RegionSelect',
  components: {
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    id: {
      type: String,
      default: 'regionSelect',
    },
    countryData: {
      type: String,
      default: '',
    },
    regionData: {
      type: String,
      default: '',
    },
    whiteList: Array,
    blackList: Array,
    className: String,
    autocomplete: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select Region',
    },
    usei18n: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  //   data: () => ({
  //     regionOptions: this.getCountryOptions(this.countryData),
  //     localValue: this.regionData,
  //     ran: false,
  //   }),
  data() {
    return {
      regionOptions: this.getCountryOptions(this.countryData),
      localValue: this.regionData,
    }
  },
  computed: {
    autocompleteAttr() {
      return this.autocomplete ? 'address-level1' : 'nope' // off (no funciona con chrome)
    },
  },
  watch: {
    countryData(newVal, oldVal) {
      // Forzamos el cambio
      this.localValue = ''
      if (oldVal !== '') {
        this.onChange('')
      }
      this.regionOptions = this.getCountryOptions(newVal)

    //   if (this.countryData) {
    //   } else {
    //     this.shownRegions = []
    //   }
    },
  },
  mounted() {
    // if (this.countryData) {
    //   this.getCountryOptions()
    // } else {
    // }
    // this.getCountryOptions(this.countryData)
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onChange(value) {
      this.$emit('input', this.localValue)
    },
    getCountryOptions(country) {
      // eslint-disable-next-line no-param-reassign
      //   country = country || this.country
      if (!country) {
        return []
      }
      let countryRegions = regions.find(elem => elem.countryShortCode === country).regions
      if (this.$i18n && this.usei18n) {
        countryRegions = countryRegions.map(region => {
          const localeRegion = { ...region }
          localeRegion.name = this.$t(region.name)

          return localeRegion
        })
        countryRegions.sort((region1, region2) => (region1.name > region2.name ? 1 : -1))
      }
      if (this.whiteList) {
        countryRegions = countryRegions.filter(region => this.whiteList.includes(region.shortCode))
      }
      if (this.blackList) {
        countryRegions = countryRegions.filter(region => !this.blackList.includes(region.shortCode))
      }
      this.ran = true

      return countryRegions
    },
  },
}
</script>
