<template>
  <!-- TODO - Sacar el v-navigation-drawer fuera para que sea menos dependiente -->
  <v-navigation-drawer
    :value="isSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-sidebar-active', val)"
  >
    <v-card class="h-full">
      <div class="drawer-header d-flex align-center justify-space-between mb-4">
        <span class="font-weight-semibold text-base text--primary">
          {{ productDemanderLocal.id ? $t('ProductDemanderList.form.header_edit') : $t('ProductDemanderList.form.header_add') }}
        </span>

        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="handleFormSubmit"
        >
          <v-select
            v-if="isAdminView"
            v-model="productDemanderLocal.demander"
            :items="demanderFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.demander"
            :label="$t('ProductDemanderList.form.demander')"
            item-text="user.firstName"
            return-object
            outlined
            dense
            :clearable="(productDemanderLocal.id ? false : true)"
            :readonly="(productDemanderLocal.id ? true : false)"
            :disabled="(productDemanderLocal.id ? true : false)"
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <!-- :rules="[validators.required]" -->
          <v-text-field
            v-model="productDemanderLocal.name"
            outlined
            dense
            :error-messages="errorMessages.name"
            :label="$t('ProductDemanderList.form.name')"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!-- Product Category -->
          <v-select
            v-model="productDemanderLocal.productCategory"
            :items="productCategoryFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.productCategory"
            hide-details="auto"
            :label="$t('ProductDemanderList.form.product_category')"
            item-text="name"
            return-object
            outlined
            :dense="false"
            clearable
            chips
            class="mb-6"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="productDemanderLocal.productCategory = null"
              >
                <v-avatar left>
                  <v-img :src="data.item.image"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item
                  v-bind="data.attrs"
                  style="height: unset;"
                  v-on="data.on"
                >
                  <v-list-item-avatar>
                    <img :src="data.item.image">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-select>

          <!-- Product -->
          <!-- <v-select
            v-model="productDemanderLocal.product"
            :items="productFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.product"
            :label="$t('ProductDemanderList.form.product')"
            item-text="name"
            return-object
            outlined
            dense
            clearable
            hide-details="auto"
            class="mb-6"
          >
          </v-select> -->
          <!-- color="blue-grey lighten-2" -->
          <v-autocomplete
            v-model="productDemanderLocal.product"
            :items="productFilterOptionsLocal"
            :rules="rules.select"
            :error-messages="errorMessages.product"
            hide-details="auto"
            :label="$t('ProductDemanderList.form.product')"
            item-text="name"
            return-object
            :outlined="true"
            :dense="false"
            clearable
            chips
            class="mb-6"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="productDemanderLocal.product = null"
              >
                <v-avatar left>
                  <v-img :src="data.item.productCategory.image"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item
                  v-bind="data.attrs"
                  style="height: unset;"
                  v-on="data.on"
                >
                  <v-list-item-avatar>
                    <img :src="data.item.productCategory.image">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.productCategory.name"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-autocomplete>

          <v-select
            v-model="productDemanderLocal.productConservation"
            :items="productConservationFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.productConservation"
            :label="$t('ProductDemanderList.form.product_conservation')"
            item-text="name"
            return-object
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-select
            v-model="productDemanderLocal.productPresentation"
            :items="productPresentationFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.productPresentation"
            :label="$t('ProductDemanderList.form.product_presentation')"
            item-text="name"
            return-object
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <!-- Flags -->
          <v-switch
            v-model="productDemanderLocal.isMarketplaceAvailable"
            class="mt-0-"
            label="In Marketplace ?"
            inset
            hide-details
          ></v-switch>

          <v-switch
            v-model="productDemanderLocal.isCampaignReceiveAvailable"
            class="mt-1-"
            label="Campaign Receive ?"
            inset
            hide-details
          ></v-switch>

          <!-- Actions -->
          <v-btn
            color="primary"
            type="submit"
            class="me-3 mt-4"
          >
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="red"
                @click.prevent="onCancelRequest"
              >
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-progress-circular>
            </div>
            <span v-else>{{ productDemanderLocal.id ? $t('ProductDemanderList.form.submit') : $t('ProductDemanderList.form.submit_add') }}</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-4"
            type="reset"
            @click.prevent="resetForm"
          >
            {{ $t('ProductDemanderList.form.reset') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useProductDemander from '@/services/api/modules/productDemander'
import useProductDemanderHandler from '@/views/apps/product/product-demander/useProductDemanderHandler'
import useProductMasterData from '../../useProductMasterData'

export default {
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    productDemander: {
      type: Object,
      required: true,
    },
    isAdminView: {
      type: Boolean,
      default: false,
    },

    // Options
    demanderFilterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    productCategoryFilterOptions: {
      type: Array,
      required: true,
    },
    productFilterOptions: {
      type: Array,
      required: true,
    },
    productConservationFilterOptions: {
      type: Array,
      required: true,
    },
    productPresentationFilterOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Company Handler module: UserRole Options, Notifications and icons
    const { sendProductDemanderNotification } = useProductDemanderHandler()

    // productDemander
    // const converter = productDemander => ({
    //   id: productDemander.id,
    //   status: productDemander.status,
    //   name: productDemander.name || '',
    //   product: productDemander.product || null,
    //   productConservation: productConservation.product || null,
    //   productPresentation: productDemander.productPresentation || null,
    // })

    // Form
    const form = ref(null)
    const loading = ref(false)
    const errorMessages = ref([])

    // Data
    const productDemanderLocal = ref(JSON.parse(JSON.stringify(props.productDemander)))
    const resetForm = () => {
      //  Inicializamos las validaciones de las reglas del formulario
      //  Eliminamos los errores
      //  Cargamos el nuevo objeto
      // eslint-disable-next-line no-use-before-define
      form.value.resetValidation()
      errorMessages.value = []
      productDemanderLocal.value = JSON.parse(JSON.stringify(props.productDemander))
    }

    // TODO - Cuando se crea uno nuevo por segunda vez, no se detecta el cambio y no se ejecuta el reset
    watch(
      [
        () => props.productDemander,
        () => props.isSidebarActive, // TODO - Para forzar el reset
      ],
      () => {
        resetForm()
      },
    )

    const { products: productFilterOptionsLocal, fetchProducts } = useProductMasterData()

    // Watch productCategory => Change search
    watch(() => productDemanderLocal.value.productCategory, () => {
      fetchProducts({ productCategoryId: productDemanderLocal.value?.productCategory?.id })
        .then(response => {
          productFilterOptionsLocal.value = response.data.products
        })
    })

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onSubmit()
    }

    const onSubmit = () => {
      // Hacemos una copia para poder manipular y no perder lo que había antes (Por si falla)
      const productDemanderData = JSON.parse(JSON.stringify(productDemanderLocal))

      // Replace Objects by id (They are mandatory but we also verify for null)
      const productDemanderDataEmit = Object.assign(productDemanderData.value, {
        demanderId: productDemanderData.value?.demander?.id,
        productCategoryId: productDemanderData.value?.productCategory?.id,
        productId: productDemanderData.value?.product?.id,
        productConservationId: productDemanderData.value?.productConservation?.id,
        productPresentationId: productDemanderData.value?.productPresentation?.id,
      })
      delete productDemanderDataEmit.product
      delete productDemanderDataEmit.productConservation
      delete productDemanderDataEmit.productPresentation

      // Set isAdminView
      if (props.isAdminView) {
        productDemanderDataEmit.isAdminView = true
      }

      if (productDemanderLocal.value.id) {
        // eslint-disable-next-line no-use-before-define
        onSubmitTask(useProductDemander.updateProductDemander(productDemanderDataEmit))
      } else {
        // eslint-disable-next-line no-use-before-define
        onSubmitTask(useProductDemander.createProductDemander(productDemanderDataEmit))
      }
    }

    const onSubmitTask = task => {
      loading.value = true

      // Task create or update
      // Promise.all(tasks)
      //   .finally(() => {
      //   })
      task
        .then(() => {
          emit('update:is-sidebar-active', false)
          emit('refetch-data')

          sendProductDemanderNotification('Updated/Created', 'success')
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            errorMessages.value = error.response.data.error
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    // Editor
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },

      // placeholder: t('productDemander.Fields.description-placeholder'),
      placeholder: 'Write your description',
    }

    return {
      // UI
      // avatarText, // Filter/Formatter
      editorOption,

      // Form validation
      form,
      handleFormSubmit,
      resetForm,
      required,

      // Data
      productDemanderLocal,
      errorMessages,
      loading,

      // Data filters
      productFilterOptionsLocal,

      // validations and icons
      validators: {
        required,
      },
      rules: {
        select: [v => !!v || 'Item is required'],
        select2: [v => v.length > 0 || 'Item is required in select 2'],
      },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
