import axios from '@axios'

// import BaseApiService from '../apiBase'

// interface UserCompanyInterface {
//     id: string;
//     name: string;
// }

// UserCompany Router list
// GET    /user/companies             Retrieves the collection of UserCompany resources (By user).
// POST   /user/companies             Creates a UserCompany resource.
// GET    /user/companies/{id}        Retrieves a UserCompany resource.
// PUT    /user/companies/{id}        Replaces a UserCompany resource.
// DELETE /user/companies             Removes all UserCompany resource (By user)
// DELETE /user/companies/{id}        Removes a UserCompany resource.
const routeUserCompanies = '/user/companies'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// Si extendemos duplicamos instancias axios
// class UserCompany extends BaseApiService { constructor() { super(axios) }
class UserCompany {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchUserCompanies(queryParams = {}) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeUserCompanies}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createUserCompany(userCompanyData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeUserCompanies}`, userCompanyData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchUserCompany(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeUserCompanies}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeUserCompany(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeUserCompanies}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateUserCompany(userCompanyData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeUserCompanies}/${userCompanyData.id}`, { ...userCompanyData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useUserCompany (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useUserCompany = new UserCompany()
export default useUserCompany
