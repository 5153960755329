<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    width="500"
    @click:outside="$emit('update:is-dialog-active',false)"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t('ProductSupplierReasignDialog.title') }}
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        {{ $t('ProductSupplierReasignDialog.text') }}
      </v-card-text>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="handleFormSubmit"
        >
          <v-select
            v-model="supplierFilter"
            :label="$t('ProductFilters.supplier')"
            :items="supplierFilterOptions"
            item-text="user.firstName"
            return-object
            outlined
            clearable
            dense
            chips
            small-chips
            hide-details="auto"
            color="primary"
            :rules="rules.select"
          ></v-select>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="$emit('update:is-dialog-active',false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
          <span class="ml-1">Cancel</span>
        </v-btn>

        <!-- @click="$emit('update:is-dialog-active',false); onProductSupplierReasign()" -->
        <v-btn
          outlined
          text
          :color="customActionReasign.color"
          @click.stop="handleFormSubmit()"
        >
          <v-icon>
            {{ customActionReasign.icon }}
          </v-icon>
          <span>{{ customActionReasign.title }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useProductSupplierHandler from '../useProductSupplierHandler'

export default {
  model: {
    prop: 'isDialogActive',
    event: 'update:is-dialog-active',
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    supplierFilterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const { customActionReasign } = useProductSupplierHandler()

    // Form
    const form = ref(null)
    const supplierFilter = ref(null)

    // TODO - Cuando se crea uno nuevo por segunda vez, no se detecta el cambio y no se ejecuta el reset
    watch(
      () => props.isDialogActive,
      () => {
        // eslint-disable-next-line no-use-before-define
        resetForm()
      },
    )
    const resetForm = () => {
      supplierFilter.value = null
      if (form.value) {
        form.value.resetValidation()
      }
    }

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onProductSupplierReasign()
    }

    const onProductSupplierReasign = () => {
      emit('update:is-dialog-active', false)
      emit('reasign', supplierFilter.value.id)
    }

    return {
      // Form and data
      form,
      handleFormSubmit,
      customActionReasign,

      // Data
      supplierFilter,

      rules: {
        select: [v => !!v || 'Item is required'],
        select2: [v => v.length > 0 || 'Item is required in select 2'],
      },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
