import { mdiAlertCircle } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useChatHandler from '@/views/apps/chat/useChatHandler'
import useCompanyHandler from '../useCompanyHandler'

// Services
import useUserCompany from '@/services/api/modules/userCompany'

export default function useCompanyUsersList() {
  const { sendCompanyNotification } = useCompanyHandler()

  const itemListTable = ref([])
  const refTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { text: 'USER', value: 'firstName', sortable: true },

    // { key: 'email', sortable: true },
    { text: 'ROLE', value: 'companyRole', sortable: true },

    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { text: 'STATUS', value: 'status', sortable: true },
    {
      text: 'S',
      value: 'seller',
      sortable: false,
      align: 'center',
    },
    {
      text: 'P',
      value: 'purchaser',
      sortable: false,
      align: 'center',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const loading = ref(false)

  // Table options
  // const perPageOptions = [10, 25, 50, 100]
  // const sortBy = ref('companyRole')
  // const isSortDirDesc = ref(false)

  // Table options
  // page: number,
  // itemsPerPage: number,
  // sortBy: string[],
  // sortDesc: boolean[],
  // groupBy: string[],
  // groupDesc: boolean[],
  // multiSort: boolean,
  // mustSort: boolean
  const options = ref({
    sortBy: ['companyRole'],
    sortDesc: [false],
    multiSort: false,
  })

  const serverItemsLength = ref(0) // Total de resultados sin paginar
  const selectedRows = ref([]) // No se

  // Filters
  // const companyId = ref(null)
  const searchQuery = ref('')
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const fetchCompanyUsers = () => {
    loading.value = true
    useUserCompany
      .fetchUserCompanies({
        q: searchQuery.value,
        perPage: options.value.itemsPerPage, // perPage.value,
        page: options.value.page, // currentPage.value,
        sortBy: options.value?.sortBy[0], // sortBy.value,
        sortDesc: options.value?.sortDesc[0], // isSortDirDesc.value,

        // Custom fiters
        // companyId: companyId.value, // todo - Creo que no se usa -> búsqueda implicita
        companyRole: roleFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { userCompanies, total } = response.data

        itemListTable.value = userCompanies
        serverItemsLength.value = total
      })
      .catch(() => {
        sendCompanyNotification('Error fetching users list', 'error', mdiAlertCircle)
      })
      .finally(() => {
        loading.value = false
      })
  }

  // watch([currentPage, perPage, companyId, searchQuery, roleFilter, statusFilter, options], () => {
  watch([searchQuery, roleFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchCompanyUsers()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Role colors, Seller and purchaser colors
  const {
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserIsSellerVariantAndIcon,
    resolveUserIsPurchaserVariantAndIcon,
  } = useCompanyHandler()

  // El estado del usuario se gestiona en el Chat
  // TODO import useChat from '@/views/apps/chat/useChatHandler'
  const { resolveUserStatus } = useChatHandler()

  return {
    // Table
    refTable,
    tableColumns,
    searchQuery,
    options,

    loading,
    selectedRows,
    itemListTable,
    serverItemsLength,

    // UI
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserIsSellerVariantAndIcon,
    resolveUserIsPurchaserVariantAndIcon,

    // UI
    // resolveUserStatusVariant,
    resolveUserStatus,

    // Data and filters
    fetchCompanyUsers,
    roleFilter,
    statusFilter,
  }
}
