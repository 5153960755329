<template>
  <v-navigation-drawer
    :value="isSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center justify-space-between mb-4">
        <!-- v-if="productSupplierLocal.id" -->
        <v-btn
          :color="resolveProductSupplierStatus(productSupplierLocal.status).color"
          @click="onClickProductSupplierStatusToggle"
        >
          {{ productSupplierLocal.status }}
        </v-btn>

        <span class="font-weight-semibold text-base text--primary">
          {{ productSupplierLocal.id ? $t('ProductSupplierList.form.header_edit') : $t('ProductSupplierList.form.header_add') }}
        </span>

        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="handleFormSubmit"
        >
          <v-select
            v-if="isAdminView"
            v-model="productSupplierLocal.supplier"
            :items="supplierFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.supplier"
            :label="$t('ProductSupplierList.form.supplier')"
            item-text="user.firstName"
            return-object
            outlined
            dense
            :clearable="(productSupplierLocal.id ? false : true)"
            :readonly="(productSupplierLocal.id ? true : false)"
            :disabled="(productSupplierLocal.id ? true : false)"
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-text-field
            v-model="productSupplierLocal.name"
            outlined
            dense
            :rules="[validators.required]"
            :error-messages="errorMessages.name"
            :label="$t('ProductSupplierList.form.name')"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!-- Product -->
          <!-- <v-select
            v-model="productSupplierLocal.product"
            :items="productFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.product"
            :label="$t('ProductSupplierList.form.product')"
            item-text="name"
            return-object
            outlined
            dense
            clearable
            hide-details="auto"
            class="mb-6"
          >
          </v-select> -->
          <v-autocomplete
            v-model="productSupplierLocal.product"
            :items="productFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.product"
            hide-details="auto"
            :label="$t('ProductSupplierList.form.product')"
            item-text="name"
            return-object
            :outlined="true"
            :dense="false"
            clearable
            chips
            class="mb-6"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="productSupplierLocal.product = null"
              >
                <v-avatar left>
                  <v-img :src="data.item.productCategory.image"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item
                  v-bind="data.attrs"
                  style="height: unset;"
                  v-on="data.on"
                >
                  <v-list-item-avatar>
                    <img :src="data.item.productCategory.image">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.productCategory.name"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-autocomplete>

          <v-select
            v-model="productSupplierLocal.productConservation"
            :items="productConservationFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.productConservation"
            :label="$t('ProductSupplierList.form.product_conservation')"
            item-text="name"
            return-object
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-select
            v-model="productSupplierLocal.productPresentation"
            :items="productPresentationFilterOptions"
            :rules="rules.select"
            :error-messages="errorMessages.productPresentation"
            :label="$t('ProductSupplierList.form.product_presentation')"
            item-text="name"
            return-object
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-combobox
            v-model="productSupplierLocal.tags"
            :items="productSupplierLocal.tags"
            :error-messages="errorMessages.tags"
            :label="$t('ProductSupplierList.form.tags')"
            outlined
            multiple
            chips
            deletable-chips
            small-chips
            hide-details="auto"
            class="mb-6"
          >
          </v-combobox>
          <!-- Actions -->
          <div class="mt-4">
            <v-btn
              color="secondary"
              outlined
              type="reset"
              class="me-3"
              @click.prevent="resetForm"
            >
              {{ $t('ProductSupplierList.form.reset') }}
            </v-btn>
            <v-btn
              color="warning"
              type="submit"
              class="me-3"
              @click.prevent="handleFormSubmit(true)"
            >
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="red"
                  @click.prevent="onCancelRequest"
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-progress-circular>
              </div>
              <span v-else>{{ productSupplierLocal.id ? $t('ProductSupplierList.form.submit') : $t('ProductSupplierList.form.submit_add') }} as Draft</span>
            </v-btn>
            <v-btn
              color="success"
              type="submit"
              @click.prevent="handleFormSubmit(false)"
            >
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="red"
                  @click.prevent="onCancelRequest"
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-progress-circular>
              </div>
              <span v-else>{{ productSupplierLocal.id ? $t('ProductSupplierList.form.submit') : $t('ProductSupplierList.form.submit_add') }}</span>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Maestros
import { PRODUCT_SUPPLIER_STATUS } from '@/services/master/ProductSupplierStatus'

// Services
import useProductSupplier from '@/services/api/modules/productSupplier'
import useProductSupplierHandler from '@/views/apps/product/product-supplier/useProductSupplierHandler'

export default {
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    productSupplier: {
      type: Object,
      required: true,
    },
    isAdminView: {
      type: Boolean,
      default: false,
    },

    // Options
    supplierFilterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    productCategoryFilterOptions: {
      type: Array,
      required: true,
    },
    productFilterOptions: {
      type: Array,
      required: true,
    },
    productConservationFilterOptions: {
      type: Array,
      required: true,
    },
    productPresentationFilterOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Company Handler module: UserRole Options, Notifications and icons
    const { sendProductSupplierNotification, resolveProductSupplierStatus } = useProductSupplierHandler()

    // ProductSupplier
    // const converter = productSupplier => ({
    //   id: productSupplier.id,
    //   status: productSupplier.status,
    //   name: productSupplier.name || '',
    //   product: productSupplier.product || null,
    //   productConservation: productConservation.product || null,
    //   productPresentation: productSupplier.productPresentation || null,
    // })

    // Form
    const form = ref(null)
    const loading = ref(false)
    const errorMessages = ref([])

    // Data
    const productSupplierLocal = ref(JSON.parse(JSON.stringify(props.productSupplier)))
    const resetForm = () => {
      //  Inicializamos las validaciones de las reglas del formulario
      //  Eliminamos los errores
      //  Cargamos el nuevo objeto
      // eslint-disable-next-line no-use-before-define
      form.value.resetValidation()
      errorMessages.value = []
      productSupplierLocal.value = JSON.parse(JSON.stringify(props.productSupplier))
    }

    // TODO - Cuando se crea uno nuevo por segunda vez, no se detecta el cambio y no se ejecuta el reset
    watch(
      [
        () => props.productSupplier,
        () => props.isSidebarActive, // TODO - Para forzar el reset
      ],
      () => {
        resetForm()
      },
    )

    // Events
    const handleFormSubmit = (saveAsDraft = true) => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onSubmit(saveAsDraft)
    }

    const onSubmit = saveAsDraft => {
      // Set as draft or Active (Except INACTIVE)
      if (saveAsDraft) {
        productSupplierLocal.value.status = PRODUCT_SUPPLIER_STATUS.DRAFT
      }

      // Set as Active (Except INACTIVE)
      if (!saveAsDraft && productSupplierLocal.value.status !== PRODUCT_SUPPLIER_STATUS.INACTIVE) {
        productSupplierLocal.value.status = PRODUCT_SUPPLIER_STATUS.ACTIVE
      }

      // Hacemos una copia para poder manipular y no perder lo que había antes (Por si falla)
      const productSupplierData = JSON.parse(JSON.stringify(productSupplierLocal))

      // Replace Objects by id (They are mandatory but we also verify for null)
      const productSupplierDataEmit = Object.assign(productSupplierData.value, {
        supplierId: productSupplierData.value?.supplier?.id,
        productId: productSupplierData.value?.product?.id,
        productConservationId: productSupplierData.value?.productConservation?.id,
        productPresentationId: productSupplierData.value?.productPresentation?.id,
      })
      delete productSupplierDataEmit.supplier
      delete productSupplierDataEmit.product
      delete productSupplierDataEmit.productConservation
      delete productSupplierDataEmit.productPresentation

      // Set isAdminView
      if (props.isAdminView) {
        productSupplierData.isAdminView = true
      }

      if (productSupplierLocal.value.id) {
        // eslint-disable-next-line no-use-before-define
        onSubmitTask(useProductSupplier.updateProductSupplier(productSupplierDataEmit))
      } else {
        // eslint-disable-next-line no-use-before-define
        onSubmitTask(useProductSupplier.createProductSupplier(productSupplierDataEmit))
      }
    }

    const onSubmitTask = task => {
      loading.value = true

      // Task create or update
      // Promise.all(tasks)
      //   .finally(() => {
      //   })
      task
        .then(() => {
          emit('update:is-sidebar-active', false)
          emit('refetch-data')

          sendProductSupplierNotification('Updated/Created', 'success')
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            errorMessages.value = error.response.data.error

            sendProductSupplierNotification(error.response.data.error.message, 'error')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    // Toggle User Enabled => on/off
    const onClickProductSupplierStatusToggle = () => {
      switch (productSupplierLocal.value.status) {
        case PRODUCT_SUPPLIER_STATUS.DRAFT:
          productSupplierLocal.value.status = PRODUCT_SUPPLIER_STATUS.ACTIVE
          break
        case PRODUCT_SUPPLIER_STATUS.ACTIVE:
          productSupplierLocal.value.status = PRODUCT_SUPPLIER_STATUS.INACTIVE
          break
        case PRODUCT_SUPPLIER_STATUS.INACTIVE:
          productSupplierLocal.value.status = PRODUCT_SUPPLIER_STATUS.DRAFT
          break
        default:
      }
    }

    // Editor
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },

      // placeholder: t('ProductSupplier.Fields.description-placeholder'),
      placeholder: 'Write your description',
    }

    return {
      // ProductSupplier status
      onClickProductSupplierStatusToggle,
      resolveProductSupplierStatus,

      // UI
      // avatarText, // Filter/Formatter
      editorOption,

      // Form validation
      form,
      handleFormSubmit,
      resetForm,
      required,

      // Data
      productSupplierLocal,
      errorMessages,
      loading,

      // validations and icons
      validators: {
        required,
      },
      rules: {
        select: [v => !!v || 'Item is required'],
        select2: [v => v.length > 0 || 'Item is required in select 2'],
      },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
