import store from '@/store'
import axios from '@axios'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = 'http://localhost:8060/api'

// TODO: CompanyUser
// CompanyUser Router list
// GET    /company/users             Retrieves the collection of CompanyUser resources.
// POST   /company/users             Creates a CompanyUser resource.
// GET    /company/users/{id}        Retrieves a CompanyUser resource.
// PUT    /company/users/{id}        Replaces a CompanyUser resource.
// DELETE /company/users/{id}        Removes a CompanyUser resource.
const routeCompanyUsers = '/company/users'

// export default class CompanyService extends BaseApiService {
export default class CompanyService {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  // Read company data: Account Settings:  GENERAL, ADDRESS
  read() {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}/company/data`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Update company attributes (Only send fields)
  updateCompany(...args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/company/update`, ...args)
        .then(response => {
          const { company } = response.data
          if (company) {
            store.commit('company/SET', company)
          }

          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  updateCompanyAccountSettingsMedia(slugAvatarImage = 'avatar', formData) {
    //  2 URL's
    //  company/update/avatar -> Upload Company Avatar
    //  company/update/image  -> Upload Company Image
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/company/update/${slugAvatarImage}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Update user Account Settings: GENERAL
  updateCompanyAccountSettingsGeneral(...args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/company/update/general`, ...args)
        .then(response => {
          const { companyData } = response.data
          if (companyData) {
            store.commit('company/SET', companyData)
          }

          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  // Update Company Account Settings: ADDRESS
  updateCompanyAccountSettingsAddress(...args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/company/update/address`, ...args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /**
   * API METHODS: COMPANY USERS NOT IN USE (See UserCompanies)
   */

  // Leer usuarios:
  //   arg = {
  //       q: searchQuery.value,
  //       perPage: perPage.value,
  //       page: currentPage.value,
  //       sortBy: sortBy.value,
  //       sortDesc: isSortDirDesc.value,
  //       companyRole: roleFilter.value,
  //       status: statusFilter.value,
  //     }
  //   fetchCompanyUsers(queryParams) {
  //     return new Promise((resolve, reject) => {
  //       this.axiosIns
  //         .get(`${this.API_BASE}/company/users/list`, { params: queryParams })
  //         .then(response => resolve(response))
  //         .catch(error => reject(error))
  //     })
  //   }

  // Leer usuario de empresa
  //   fetchCompanyUser({ id }) {
  //     return new Promise((resolve, reject) => {
  //       this.axiosIns
  //         .get(`${this.API_BASE}/company/users/data/${id}`)
  //         .then(response => resolve(response))
  //         .catch(error => reject(error))
  //     })
  //   }

  // Create Company User (Requiere authentificacion)
  // args = { firstName, username, companyRole, seller, purchaser } => { userData }
  // UserService =>  create .put(`${this.API_BASE}/user/create`, ...args)
  //   createCompanyUser(...args) {
  //     return new Promise((resolve, reject) => {
  //       this.axiosIns
  //         .put(`${this.API_BASE}/company/users/create`, ...args)
  //         .then(response => resolve(response))
  //         .catch(error => reject(error))
  //     })
  //   }

  fetchCompanyUsers(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeCompanyUsers}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createCompanyUser(companyUserData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_BASE}${routeCompanyUsers}`, { ...companyUserData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchCompanyUser(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeCompanyUsers}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateCompanyUser(companyUserData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeCompanyUsers}/${companyUserData.id}`, { ...companyUserData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeCompanyUser(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.API_BASE}${routeCompanyUsers}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}
