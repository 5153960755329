import { mdiAlertCircle } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useChatHandler from '../useChatHandler'

// Services
import useContact from '@/services/api/modules/contact'

export default function useContactUsersList(isAdminView = false) {
  const { sendChatNotification } = useChatHandler()

  const contacts = ref([])
  const refTable = ref(null)

  // Table Handlers
  // { key: 'status', sortable: true },
  // { key: 'name', sortable: true },
  // { key: 'tags', sortable: false },
  // { key: 'actions' },

  // value representa:
  //    El valor de ordenación en el servidor: sortBy: ['contact.name']
  //    El nombre de la columna: <template #[`item.contact.name`]="{item}">
  //
  //    contact.contact.firstName => contact.firstName (Ver doctrine Order) (contact.contact(User))
  //
  // Doctrine conversions
  //    'userFirstName'          => 'user.firstName',            Only for order
  //    'userContactFirstName'   => 'userContact.firstName',     Filter and order
  //    'userContactLastName'    => 'userContact.lastName',      Filter and order
  //    'userContactCompanyName' => 'userContactCompany.name',   Filter and order
  const tableColumns = [
    { text: 'STATUS', value: 'status', sortable: true },
    { text: 'CONTACT', value: 'userContactCompanyName', sortable: true },
    { text: 'TAGS', value: 'tags', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  // User opcional (Se visualiza con Admin View)
  if (isAdminView) {
    tableColumns.unshift({ text: 'USER', value: 'userFirstName', sortable: true })
  }

  const loading = ref(false)

  // Añadimos este atributo en options pero podría ir solo - Hay que indicarlo en el footer-props
  // ItemsPerPageOptions: [10, 25, 50, 100] - https://vuetifyjs.com/en/api/v-data-footer/#props-items-per-page-options
  // :footer-props="{
  //   'items-per-page-options': options.itemsPerPageOptions // [10, 25, 50, 100]
  // }"

  // DataOptions object https://vuetifyjs.com/en/api/v-data-table/#props-options
  //  page: number,
  //  itemsPerPage: number,
  //  sortBy: string[],
  //  sortDesc: boolean[],
  //  groupBy: string[],
  //  groupDesc: boolean[],
  //  multiSort: boolean,
  //  mustSort: boolean
  // El resto de los atributos los añade v-data-table al sincronizar
  const options = ref({
    // Pagination
    page: 1,
    itemsPerPage: 5,

    // Sort default: createdAt DESC (Recent first)
    sortBy: ['createdAt'],
    sortDesc: [true],
    multiSort: false,
    mustSort: false,

    // Group (Lo indicamos para que v-data-table no genere cambios sobre options y evitar que watch haga reload)
    groupBy: [],
    groupDesc: [],
  })

  const itemsPerPageOptions = ref([1, 2, 5, 10, 15, -1])
  const serverItemsLength = ref(0) // Total de resultados sin paginar
  const selectedContacts = ref([])

  // Filters
  const searchQueryFilter = ref('')

  // Filters (Custom)
  const countFiltering = ref(0)
  const userFilter = ref(null)
  const statusFilter = ref(null) // object(value: String, text: String) (ContactStatusList)

  const fetchContacts = () => {
    loading.value = true
    selectedContacts.value = []

    // debugger
    useContact
      .fetchContacts({
        q: searchQueryFilter.value,
        perPage: options.value.itemsPerPage,
        page: options.value.page,
        sortBy: options.value?.sortBy[0],
        sortDesc: options.value?.sortDesc[0],

        // Custom filters
        isAdminView,
        userId: userFilter.value?.id,
        status: statusFilter.value,
      })
      .then(response => {
        const { contacts: list, total } = response.data

        contacts.value = list
        serverItemsLength.value = total
      })
      .catch(() => {
        sendChatNotification('Error fetching catalog', 'error', mdiAlertCircle)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const refetchDataByFilters = () => {
    // Cuando aplicamos filtros el resultado ya no va acorde a la página actual
    if (options.value.page !== 1) {
      options.value.page = 1 // Esto ya va a provocar un Fetch
    } else {
      fetchContacts()
    }
  }

  // Paginations
  //  Page:                           Solo cambio de página
  //  ItemsPerPage, sortBy, sortDesc: Forzamos a Page 1
  watch(
    [
      () => options.value.page,
      () => options.value.itemsPerPage,
      () => options.value.sortBy[0],
      () => options.value.sortDesc[0],
    ],
    (newValue, oldValue) => {
      // Changes on: ItemsPerPage OR sortBy OR sortDesc
      if (
        newValue[1] !== oldValue[1] // ItemsPerPage
        || newValue[2] !== oldValue[2] // sortBy has changed
        || newValue[3] !== oldValue[3] // sortDesc has changed
      ) {
        refetchDataByFilters()
      } else {
        fetchContacts()
      }
    },
  )

  // array of multiple sources: No necesitamos { deep: true }
  // watch([searchQueryFilter, Status, Category, Product, ProductConservation, ProductPresentation])
  watch(
    [
      // options,
      searchQueryFilter,
      userFilter,
      statusFilter,
    ],
    () => {
      refetchDataByFilters()
    },
  )

  // First search
  refetchDataByFilters()

  return {
    // Table
    refTable,
    tableColumns,
    options,
    itemsPerPageOptions, // Select Item per Page

    loading,
    selectedContacts,
    contacts, // data
    serverItemsLength,

    // Fetch data
    fetchContacts,

    // Filters
    searchQueryFilter,

    // Filters(Custom): Status, UserCompany
    countFiltering,
    userFilter,
    statusFilter,
  }
}
