import {
  mdiArrowDownCircle, mdiCheckCircle, mdiClose, mdiInformation, mdiShield, mdiAccountSwitchOutline,
} from '@mdi/js'

// Notification
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'


export default function useProductDemanderHandler() {
  // Esto es para traducir las constantes (Textos fijos como Roles, Estados, ...)
  // const { t, te } = useI18nUtils()
  const toast = useToast()

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendProductDemanderNotification = (title, variant = TYPE.ERROR, icon = mdiShield) => {
    // toast.warning(`Product Supplier ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `ProductDemander - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER, type: variant },
    )
  }

  // CUSTOM ACTIONS
  const customActionReasign = {
    title: 'Reasign',
    value: 'reasign',
    color: 'success',
    icon: mdiAccountSwitchOutline,
  }

  return {
    // UI
    sendProductDemanderNotification,
    customActionReasign,
  }
}
