<template>
  <v-tooltip
    top
    :color="productDemander.isMarketplaceAvailable ? 'primary' : 'grey lighten-1'"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Desactivamos cuando No sea navegable y el indicador === false -->
      <!-- :to="menuItemMarketplace.route" -->
      <v-icon
        class="mx-2"
        :color="productDemander.isMarketplaceAvailable ? 'primary' : 'grey lighten-1'"
        :disabled="isNavegable && !productDemander.isMarketplaceAvailable"
        v-bind="attrs"
        v-on="on"
        @click.stop="goTo(menuItemMarketplace.route, productDemander)"
      >
        {{ menuItemMarketplace.icon }}
      </v-icon>
    </template>
    <!-- Marketplace Available  -->
    <!-- {{ menuItemMarketplace.title }} -->
    <span>{{ menuItemMarketplace.title }}? {{ productDemander.isMarketplaceAvailable ? '' : '(off)' }}</span>
  </v-tooltip>
</template>
<script>

// Router
import { useRouter } from '@core/utils'
import useRouterPath from '@/router/useRouterPath'

export default {
  props: {
    productDemander: {
      type: Object,
      required: true,
    },
    isNavegable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Control de navegación
    const { menuItemMarketplace } = useRouterPath()
    const { router } = useRouter()

    // Go to Marketplace with product preselected: Añadimos el query a la ruta
    const goTo = (toRoute, productDemander) => {
      // Exit if not navegable
      if (!props.isNavegable) {
        return
      }
      const newRoute = { ...toRoute, query: { productDemanderId: productDemander.id } }

      router.replace(newRoute)
    }

    return {
      // Router navigation
      menuItemMarketplace,
      goTo,
    }
  },
}
</script>
