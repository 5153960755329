<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <ModalFull
      v-show="isModalFullVisible"
      :options="{ header: { title: 'Avatar' }}"
      @close="closeModalFull"
    >
      <template v-slot:body>
        <CropperAvatarRotateModal
          v-if="companyAvatarLocal"
          ref="cropperAbatarRotateModal"
          :image-data="companyAvatarLocal"
          @cancel="closeModalFull()"
          @finish="onSubmitImage($event); closeModalFull()"
        />
      </template>
    </ModalFull>

    <v-card-text class="d-flex">
      <!-- @click="onClickImage()" -->
      <!-- @click="refInputImage.click()" -->
      <v-avatar
        rounded
        size="120"
        class="me-6"
        style="border: black solid 0.5px;"
        @click="onClickImage()"
      >
        <v-img :src="companyGeneralLocal.avatar"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <!-- @click="$refs.refInputImage.click()" -->
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="refInputImage.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">{{ $t('CompanySettings.body.tab1.avatar.buttom_upload') }}</span>
        </v-btn>

        <input
          ref="refInputImage"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
          @input="uploadImageInput($event)"
        />
        <!-- <v-file-input
          ref="refInputImage"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
        ></v-file-input> -->

        <v-btn
          color="error"
          outlined
          class="mt-5"
          @click="onSubmitImage('')"
        >
          <!-- Reset -->
          {{ $t('CompanySettings.body.tab1.avatar.buttom_reset') }}
        </v-btn>
        <p class="text-sm mt-5">
          <!-- Allowed JPG, GIF or PNG. Max size of 800K -->
          {{ $t('CompanySettings.body.tab1.avatar.text') }}
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="companyGeneralLocal.name"
              outlined
              :label="$t('CompanySettings.body.tab1.form.name')"
              hide-details="auto"
              :error-messages="errorMessages.name"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="companyGeneralLocal.slug"
              outlined
              :label="$t('CompanySettings.body.tab1.form.slug')"
              hide-details="auto"
              :error-messages="errorMessages.slug"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <!--
                  renderComponentCountrySelect: forze re-render (optional)
                  country-language: translate without re-render (optional)
              -->
            <CountrySelect
              id="company-country"
              v-model="companyGeneralLocal.country"
              :country-data="companyGeneralLocal.country"
              :placeholder="$t('CompanySettings.body.tab1.form.country')"
              :error-messages="errorMessages.country"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-text-field
              v-model="companyGeneralLocal.taxid"
              outlined
              :label="$t('CompanySettings.body.tab1.form.taxid')"
              hide-details="auto"
              :error-messages="errorMessages.taxid"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              class="me-3 mt-4"
            >
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="red"
                  @click.prevent="onCancelRequest"
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-progress-circular>
              </div>
              <span v-else>{{ $t('CompanySettings.body.tab1.form.submit') }}</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              {{ $t('CompanySettings.body.tab1.form.reset') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// Components
import CountrySelect from '@/components/CountrySelect.vue'
import CropperAvatarRotateModal from '@/components/CropperAvatar/CropperAvatarRotateModal.vue'
import ModalFull from '@/components/ModalFull.vue'

// Services
import useCompany from '@/services/api/modules/company/useCompany'
import useCompanyHandler from '@/views/apps/company/useCompanyHandler'

export default {
  components: {
    ModalFull,
    CropperAvatarRotateModal,
    CountrySelect,
  },
  props: {
    companyGeneralData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { sendCompanyNotification } = useCompanyHandler()

    // To view converter .....
    const converter = companyGeneralData => companyGeneralData

    // Data
    const companyGeneralLocal = ref(JSON.parse(JSON.stringify(converter(props.companyGeneralData))))
    const resetForm = () => {
      companyGeneralLocal.value = JSON.parse(JSON.stringify(converter(props.companyGeneralData)))
    }
    const errorMessages = ref([])

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onSubmit()
    }

    const onSubmit = () => {
      loading.value = true

      // companyGeneralLocal.avatar = this.generalData.avatar
      useCompany
        .updateCompanyAccountSettingsGeneral({
          // avatar: companyGeneralLocal.value.avatar, // Post Request propio -> Upload avatar
          name: companyGeneralLocal.value.name,
          slug: companyGeneralLocal.value.slug,
          country: companyGeneralLocal.value.country,
          taxid: companyGeneralLocal.value.taxid,
          seller: companyGeneralLocal.value.seller,
          purchaser: companyGeneralLocal.value.purchaser,
          phone: companyGeneralLocal.value.phone,
          email: companyGeneralLocal.value.email,
          site: companyGeneralLocal.value.site,
          about: companyGeneralLocal.value.about,
          productInfo: companyGeneralLocal.value.productInfo,
        })
        .then(response => {
          const { company } = response.data
          if (company && company.general) {
            companyGeneralLocal.value = converter(company.general)
          }
          sendCompanyNotification('Se ha cambiado correctamente', 'success')
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // refFormObserver.setErrors(error.response.data.error)
            errorMessages.value = error.response.data.error

            sendCompanyNotification(error.response.data.error.message, 'error')
          }
          // eslint-disable-next-line no-console
          // console.error(error)
        })
        .finally(() => {
          loading.value = false
        })
    }

    // >>> IMAGE
    const companyAvatarLocal = ref(null)

    // Upload Image
    const onSubmitImage = blobImage => {
      // Add the form data we need to submit
      const formData = new FormData()
      formData.append('avatar', blobImage)

      // Eliminamos el Blob de la variable (Despues del upload se actualiza)
      companyAvatarLocal.value = ''

      // Make the request to the POST /single-file URL
      useCompany
        .updateCompanyAccountSettingsMedia('avatar', formData)
        .then(response => {
          const { companyData } = response.data
          if (companyData && companyData.general) {
            companyGeneralLocal.value.avatar = companyData.general.avatar
            companyAvatarLocal.value = companyData.general.avatar
          }

          // SharedUtils.makeToast(this, 'success', 'Logo', 'Uploaded !!')
          // toast.success('Uploaded')
          sendCompanyNotification('Uploaded', 'success')
        })
        .catch(() => {
          // SharedUtils.makeToast(this, 'error', 'Logo', 'Error !!')
          // toast.error('Logo')
          sendCompanyNotification('Logo', 'error')
        })
    }

    // ModalFull control
    const isModalFullVisible = ref(false)
    const showModalFull = () => {
      isModalFullVisible.value = true
    }
    const closeModalFull = () => {
      isModalFullVisible.value = false
    }

    const refInputImage = ref(null)

    // >>> Go to Cropper
    // onClickImage => Event: Se lanza al hacer click sobre el avatar
    const onClickImage = () => {
      // Ya nos deja editar la imagen porque hemos resuelto CORS
      companyAvatarLocal.value = companyGeneralLocal.value.avatar
      showModalFull()

      // Solicitamos imagen
      // No nos deja hacer click desde el controlador => Lo ejecutamos desde el tag(Avatar or Button): @click="refInputImage.click()"
      // refInputImage.value.click()
    }

    // onSelectImageInput => Event: Se lanza cuando hemos seleccionado una imagen nueva
    const onSelectImageInput = imageRendered => {
      companyAvatarLocal.value = imageRendered
      showModalFull()
    }

    // @input =>> send File
    const uploadImageInput = event => {
      const file = event.srcElement.files[0] // Extract file from input event (Input Type file)

      // create a new FileReader to read this image and convert to base64 format
      const reader = new FileReader()

      // Define a callback function to run, when FileReader finishes its job
      reader.onload = e => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        // companyAvatarLocal.value = e.target.result
        onSelectImageInput(e.target.result)

        // Reset input: Preparamos para la siguiente
        // refInputImage.value.reset()
        refInputImage.value = null
      }

      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(file)
    }

    // <<< IMAGE

    return {
      // Image
      companyAvatarLocal,
      onClickImage,
      refInputImage, // ImageSelect
      onSelectImageInput,
      uploadImageInput,
      onSubmitImage,

      // Modal for image wrapper
      isModalFullVisible,
      showModalFull,
      closeModalFull,

      // Form validation
      form,
      handleFormSubmit,
      resetForm,
      required,

      // Interface events
      loading,

      // onCancelRequest,
      // onUserLogin, // No se usa en el Template

      // Data
      companyGeneralLocal,
      errorMessages,

      icons: {
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
