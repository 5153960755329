<template>
  <v-navigation-drawer
    :value="isSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center justify-space-between mb-4">
        <v-btn
          v-if="companyUserLocal.id"
          :color="resolveUserEnabledVariantAndIcon(companyUserLocal.enabled).variant"
          @click="onClickUserEnabledToggle"
        >
          {{ companyUserLocal.enabled ? 'active' : 'inactived' }}
        </v-btn>

        <span class="font-weight-semibold text-base text--primary">
          {{ companyUserLocal.id ? $t('CompanySettings.body.tab3.form.header_edit') : $t('CompanySettings.body.tab3.form.header_add') }}
        </span>

        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="handleFormSubmit"
        >
          <v-text-field
            v-model="companyUserLocal.firstName"
            outlined
            dense
            :rules="[validators.required]"
            :error-messages="errorMessages.firstName"
            :label="$t('CompanySettings.body.tab3.form.first_name')"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="companyUserLocal.username"
            :rules="[validators.required, validators.emailValidator]"
            :error-messages="errorMessages.username"
            outlined
            dense
            type="email"
            :label="$t('CompanySettings.body.tab3.form.username')"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!-- Company opcions: Seller and Purchaser profile -->
          <div class="mb-6">
            <span>{{ $t('CompanySettings.body.tab3.form.options.text') }}</span>

            <v-switch
              v-model="companyUserLocal.seller"
              :disabled="!companyProfileSellerActive"
              :label="$t('CompanySettings.body.tab3.form.options.seller')"
              :color="resolveUserIsSellerVariantAndIcon(companyProfileSellerActive).variant"
              hide-details
              class="mt-0 mx-2"
            ></v-switch>
            <v-switch
              v-model="companyUserLocal.purchaser"
              :disabled="!companyProfilePurchaserActive"
              :label="$t('CompanySettings.body.tab3.form.options.purchaser')"
              :color="resolveUserIsPurchaserVariantAndIcon(companyProfilePurchaserActive).variant"
              hide-details
              class="mt-0 mx-2"
            ></v-switch>
          </div>

          <v-select
            v-model="companyUserLocal.companyRole"
            :rules="[validators.required]"
            :error-messages="errorMessages.companyRole"
            :label="$t('CompanySettings.body.tab3.form.company_role')"
            :items="userRoleOptions"
            item-text="text"
            item-value="value"
            outlined
            dense
            class="mb-6"
            hide-details="auto"
          >
          </v-select>
          <!-- Actions -->
          <v-btn
            color="primary"
            type="submit"
            class="me-3 mt-4"
          >
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="red"
                @click.prevent="onCancelRequest"
              >
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-progress-circular>
            </div>
            <span v-else>{{ companyUserLocal.id ? $t('CompanySettings.body.tab3.form.submit') : $t('CompanySettings.body.tab3.form.submit_add') }}</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-4"
            type="reset"
            @click.prevent="resetForm"
          >
            {{ $t('CompanySettings.body.tab3.form.reset') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Services
import useJwt from '@/services/api/modules/auth/useJwt' // Auto-login
import useUserCompany from '@/services/api/modules/userCompany'
import useCompanyHandler from '@/views/apps/company/useCompanyHandler'

export default {
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    companyUser: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    // Company Handler module: UserRole Options, Notifications and icons
    const {
      userRoleOptions,
      sendCompanyNotification,
      resolveUserEnabledVariantAndIcon,
      resolveUserIsSellerVariantAndIcon,
      resolveUserIsPurchaserVariantAndIcon,
    } = useCompanyHandler()

    // Seller
    const companyProfileSellerActive = ref(store.state.company.seller)
    watch(
      () => store.state.company.seller,
      () => {
        companyProfileSellerActive.value = store.state.company.seller
      },
    )

    // Watch Purchaser
    const companyProfilePurchaserActive = ref(store.state.company.purchaser)
    watch(
      () => store.state.company.purchaser,
      () => {
        companyProfilePurchaserActive.value = store.state.company.purchaser
      },
    )

    // CompanyUser
    // const converter = companyUser => ({
    //   id: companyUser.id,
    //   enabled: false,
    //   firstName: companyUser.firstName || '',
    //   username: companyUser.username || '',
    //   companyRole: companyUser.companyRole || 'staff',
    //   seller: companyUser.seller || false,
    //   purchaser: companyUser.purchaser || false,
    // })

    // Data
    const companyProfileOptionsSelected = ref([])
    const companyUserLocal = ref(JSON.parse(JSON.stringify(props.companyUser)))
    const resetForm = () => {
      // eslint-disable-next-line no-use-before-define
      form.value.resetValidation()
      companyUserLocal.value = JSON.parse(JSON.stringify(props.companyUser))

      companyProfileOptionsSelected.value = []
      if (props.companyUser?.seller) {
        companyProfileOptionsSelected.value.push('seller')
      }
      if (props.companyUser?.purchaser) {
        companyProfileOptionsSelected.value.push('purchaser')
      }
    }
    watch(() => props.companyUser, () => {
      resetForm()
    })
    const errorMessages = ref([])

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onSubmit()
    }

    const onSubmit = () => {
      // debugger
      // Send checkbox group (Array ==> boolean)
      //  [ 'seller' ]    => seller = true
      //  [ 'purchaser' ] => purchaser = true
      // companyUserLocal.value.seller = !!companyProfileOptionsSelected.value.find(element => element === 'seller')
      // companyUserLocal.value.purchaser = !!companyProfileOptionsSelected.value.find(element => element === 'purchaser')

      if (companyUserLocal.value.id) {
        // eslint-disable-next-line no-use-before-define
        onSubmitTask(
          useUserCompany.updateUserCompany({
            id: companyUserLocal.value.id,
            firstName: companyUserLocal.value.firstName,
            username: companyUserLocal.value.username,
            companyRole: companyUserLocal.value.companyRole,
            seller: companyUserLocal.value.seller,
            purchaser: companyUserLocal.value.purchaser,
          }),
        )
      } else {
        // eslint-disable-next-line no-use-before-define
        onSubmitTask(
          useUserCompany.createUserCompany({
            firstName: companyUserLocal.value.firstName,
            username: companyUserLocal.value.username,
            companyRole: companyUserLocal.value.companyRole,
            seller: companyUserLocal.value.seller,
            purchaser: companyUserLocal.value.purchaser,
          }),
        )
      }
    }

    const onSubmitTask = task => {
      loading.value = true

      // Task create or update
      // Promise.all(tasks)
      //   .finally(() => {
      //   })
      task
        .then(response => {
          // Exception: Si es el mismo usuario: UserCompany === User => Actualizamos roles
          const { userCompany } = response.data
          if (userCompany && userCompany.id === store.state.user?.userData?.id) {
            // Option 1: Reload current user (Auto update store) => Profiles and roles are updated
            useJwt.accessToken().finally()

            // Option 2: Cambiar solo las opciones modificadas
            // store.commit('user/CHANGE_SELLER', userCompany?.seller || false)
            // store.commit('user/CHANGE_PURCHASER', userCompany?.purchaser || false)
            // store.commit('user/CHANGE_ROLE', userCompany?.companyRole || false)
            // store.state.user.seller = userCompany?.seller || false
            // store.state.user.purchaser = userCompany?.purchaser || false
          }
          emit('update:is-sidebar-active', false)
          emit('refetch-data')

          sendCompanyNotification('Updated/Created', 'success')
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // refFormObserver.setErrors(error.response.data.error)
            errorMessages.value = error.response.data.error

            sendCompanyNotification(error.response.data.error.message, 'error')

          // } else {
            // sendCompanyNotification(error.message, 'error')
            // eslint-disable-next-line no-console
            // console.error(error)
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    // Toggle User Enabled => on/off
    const onClickUserEnabledToggle = () => {
      companyUserLocal.value.enabled = !companyUserLocal.value.enabled
    }

    return {
      // CompanyUser Status on/off (Login or not login enabled)
      onClickUserEnabledToggle,
      resolveUserEnabledVariantAndIcon,
      resolveUserIsSellerVariantAndIcon,
      resolveUserIsPurchaserVariantAndIcon,

      // Opciones permitidas (Admin, staff)
      userRoleOptions,

      // Seller / purchaser (Las permitidas por la empresa)
      companyProfileSellerActive,
      companyProfilePurchaserActive,
      companyProfileOptionsSelected,

      // Form validation
      form,
      handleFormSubmit,
      resetForm,
      required,

      // Data
      companyUserLocal,
      errorMessages,
      loading,

      // validations and icons
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
