import {
  mdiShield, mdiAccountSwitchOutline,
} from '@mdi/js'

// Notification
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
import { PRODUCT_SUPPLIER_STATUS, resolveProductSupplierStatus } from '@/services/master/ProductSupplierStatus'

export default function useProductSupplierHandler() {
  // Esto es para traducir las constantes (Textos fijos como Roles, Estados, ...)
  // const { t, te } = useI18nUtils()
  const toast = useToast()

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendProductSupplierNotification = (title, variant = TYPE.ERROR, icon = mdiShield) => {
    // toast.warning(`Product Supplier ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `ProductSupplier - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER },
    )
  }

  // CUSTOM ACTIONS
  const customActionReasign = {
    title: 'Reasign',
    value: 'reasign',
    color: 'success',
    icon: mdiAccountSwitchOutline,
  }

  return {
    // UI
    sendProductSupplierNotification,
    resolveProductSupplierStatus,
    PRODUCT_SUPPLIER_STATUS,
    customActionReasign,
  }
}
