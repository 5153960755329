<template>
  <v-card class="h-full user-profile-sidebar-content">
    <div class="">
      <!-- Close Button -->
      <div
        class="pt-2 me-2"
        :class="$vuetify.rtl ? 'text-left' : 'text-right'"
      >
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-active', false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <!-- ContactCard component -->
      <ContactCard
        :contact="contact"
        big
      ></ContactCard>

      <!-- <perfect-scrollbar
        class="ps-chat-user-profile-sidebar-content pt-2 pb-6 px-6"
        :options="perfectScrollbarOptions"
      > -->
      <div class="pt-2 pb-6 px-6">
        <!-- Contact status -->
        <div>
          <v-radio-group
            v-model="contactLocal.status"
            column
            @change="onChangeContactStatus(contactLocal.status)"
          >
            <template v-slot:label>
              <div class="d-flex align-center">
                <v-icon class="me-2">
                  {{ icons.mdiAccountCheck }}
                </v-icon>
                <h3>Contact <strong>status</strong></h3>
              </div>
            </template>
            <v-radio
              v-for="option in contactStatusOptions"
              :key="option.value"
              :label="option.text"
              :color="option.color"
              :value="option.value"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- Contact Tags -->
        <div class="mt-2">
          <div class="d-flex align-center">
            <v-icon class="me-2">
              {{ icons.mdiBookmarkOutline }}
            </v-icon>
            <h3>Contact <strong>tags</strong></h3>
          </div>
          <v-combobox
            v-model="contactLocal.tags"
            :label="$t('ProductSupplierList.form.tags')"
            outlined
            multiple
            chips
            deletable-chips
            small-chips
            hide-details="auto"
            class="mt-2"
            @input="onChangeContactTags(contactLocal.tags)"
          >
          </v-combobox>
        </div>

        <!-- About -->
        <div class="mt-2">
          <span
            for="textarea-user-about"
            class="text-xs text--disabled"
            :class="rootThemeClasses"
          >ABOUT</span>
          <p class="mt-3">
            {{ contact.contact.about }}
          </p>
        </div>

        <!-- Personal Information -->
        <div class="mb-5">
          <span
            class="text-xs text--disabled"
            :class="rootThemeClasses"
          >
            PERSONAL INFORMATION
          </span>
          <!-- <div class="d-flex align-center my-3">
            <v-icon class="me-2">
              {{ icons.mdiEmailOutline }}
            </v-icon>
            <span>{{ contact.contact.email }}</span>
          </div> -->

          <!-- Mail -->
          <div
            class="d-flex align-center"
            @click="menuItemMailContact.href"
          >
            <v-btn
              :disabled="!menuItemMailContact.href"
              color="primary"
              class="cursor-pointer"
              icon
              :href="menuItemMailContact.href"
              :target="menuItemMailContact.target"
            >
              <v-icon class="">
                {{ menuItemMailContact.icon }}
              </v-icon>
            </v-btn>
            <span>{{ menuItemMailContact.value }}</span>
          </div>

          <!-- Phone -->
          <div class="d-flex align-center">
            <v-btn
              :disabled="!menuItemPhoneContact.href"
              color="primary"
              class="cursor-pointer"
              icon
              :href="menuItemPhoneContact.href"
              :target="menuItemPhoneContact.target"
            >
              <v-icon>{{ menuItemPhoneContact.icon }}</v-icon>
            </v-btn>
            <span>{{ menuItemPhoneContact.value }}</span>
          </div>

          <!-- Whatsapp -->
          <div class="d-flex align-center">
            <v-btn
              :disabled="!menuItemWhatsappContact.href"
              color="primary"
              class="cursor-pointer"
              icon
              :href="menuItemWhatsappContact.href"
              :target="menuItemWhatsappContact.target"
            >
              <v-icon>{{ menuItemWhatsappContact.icon }}</v-icon>
            </v-btn>
            <span>{{ menuItemWhatsappContact.value }}</span>
          </div>

          <!-- Horario -->
          <div class="d-flex align-center ml-2 mt-1">
            <v-icon class="me-2">
              {{ icons.mdiClockOutline }}
            </v-icon>
            <span>Mon - Fri 10AM - 8PM</span>
          </div>
        </div>

        <!-- Options -->
        <div>
          <span
            class="text-xs text--disabled"
            :class="rootThemeClasses"
          >
            OPTIONS
          </span>
          <!-- <div class="d-flex align-center mb-3">
            <v-icon class="me-2">
              {{ icons.mdiStarOutline }}
            </v-icon>
            <span>Important Contact</span>
          </div> -->

          <!-- Important Contact -->
          <div class="d-flex align-center">
            <v-btn
              :disabled="true"
              icon
              color=""
              @click.stop="$emit('on-action-star', contact)"
            >
              <v-icon>{{ icons.mdiStarOutline }}</v-icon>
            </v-btn>
            <span>Important Contact</span>
          </div>

          <!-- Delete Contact -->
          <div class="d-flex align-center">
            <v-btn
              icon
              color="error"
              @click.stop="$emit('on-action-delete', contact)"
            >
              <v-icon> {{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
            <span>Delete Contact</span>
          </div>

          <!-- Block Contact -->
          <div class="d-flex align-center">
            <v-btn
              :disabled="true"
              icon
              color="error"
              @click.stop="$emit('on-action-block', contact)"
            >
              <v-icon> {{ icons.mdiBlockHelper }}</v-icon>
            </v-btn>
            <span>Block Contact</span>
          </div>

          <!-- <div class="d-flex align-center">
            <v-icon
              class="me-3 ms-1"
              size="18"
            >
              {{ icons.mdiBlockHelper }}
            </v-icon>
            <span>Block Contact</span>
          </div> -->
        </div>
      </div>
      <!-- </perfect-scrollbar> -->
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountCheck,
  mdiBlockHelper,
  mdiBookmarkOutline,
  mdiClockOutline,
  mdiClose,
  mdiEmailOutline,
  mdiPhoneOutline,
  mdiStarOutline,
  mdiTrashCanOutline,
  mdiWhatsapp,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

// UI
import { avatarText } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// Services
import useContact from '@/services/api/modules/contact'
import useContactHandler from '../useContactHandler'

// Components
import ContactCard from '@/components/Cards/ContactCard.vue'

export default {
  components: {
    ContactCard,
    PerfectScrollbar,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    isAdminView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { resolveContactStatus } = useContactHandler()
    const { rootThemeClasses } = useVuetify()

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const { contactStatusOptions, sendContactNotification } = useContactHandler()

    // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
    // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
    const contactLocal = ref(JSON.parse(JSON.stringify(props.contact)))
    watch(
      () => props.contact,
      () => {
        contactLocal.value = JSON.parse(JSON.stringify(props.contact))
      },
    )

    /* Contact Actions */
    const onChangeContactStatus = newStatus => {
      // useChat.updateChatContactProfileStatus(contactLocal.value.id, { status: newStatus })
      // useContact.updateContact(contactLocal.value.id, { status: newStatus })
      useContact
        .updateContactStatus(contactLocal.value.id, { status: newStatus })
        .then(response => {
          const { contact } = response.data
          if (contact) {
            contactLocal.value = contact
          }
          sendContactNotification('Updated !!')
        })
        .catch(error => {
          // Roolback ui
          contactLocal.value.status = props.contact.status

          if (error.response && error.response.data && error.response.data.error) {
            sendContactNotification(error.response.data.error.message, 'error')
          }
        })
    }
    const onChangeContactTags = newTags => {
      useContact
        .updateContact(contactLocal.value.id, { tags: newTags })
        .then(response => {
          const { contact } = response.data
          if (contact) {
            contactLocal.value = contact
          }
          sendContactNotification('Updated !!')
        })
        .catch(error => {
          // Roolback ui
          contactLocal.value.tags = props.contact.tags

          if (error.response && error.response.data && error.response.data.error) {
            sendContactNotification(error.response.data.error.message, 'error')
          }
        })
    }

    // href: 'mailto:sferrol@empromar.com?subject=FishNet'
    const menuItemMailContact = computed(() => ({
      id: 'mail',
      title: 'Mail',
      icon: mdiEmailOutline,
      href: contactLocal.value?.userContact?.email ? `mailto:${contactLocal.value?.userContact?.email}` : null,
      value: contactLocal.value?.userContact?.email,
      target: '',
    }))

    // href: tel:655522118
    const menuItemPhoneContact = computed(() => ({
      id: 'phone',
      title: 'phone',
      icon: mdiPhoneOutline,
      href: contactLocal.value?.userContact?.phone ? `tel:${contactLocal.value?.userContact?.phone}` : null,
      value: contactLocal.value?.userContact?.phone,
      target: '',
    }))

    // https://wa.me/34655522118
    const menuItemWhatsappContact = computed(() => ({
      id: 'phone',
      title: 'whatsapp',
      icon: mdiWhatsapp,
      href: contactLocal.value?.userContact?.phone ? `https://wa.me/${contactLocal.value?.userContact?.phone}` : null,
      value: contactLocal.value?.userContact?.phone,
      target: '_blank',
    }))

    return {
      // Contact
      contactLocal,
      onChangeContactTags, // Event change
      onChangeContactStatus, // Event chage
      contactStatusOptions,

      // Contact status
      resolveContactStatus,
      menuItemMailContact,
      menuItemPhoneContact,
      menuItemWhatsappContact,

      // Perfect Scrollbar
      perfectScrollbarOptions,

      // Vuetify
      rootThemeClasses,

      // Filter
      avatarText,

      // Icons
      icons: {
        mdiClose,
        mdiAccountCheck,
        mdiEmailOutline,
        mdiBookmarkOutline,
        mdiPhoneOutline,
        mdiClockOutline,
        mdiStarOutline,
        mdiTrashCanOutline,
        mdiBlockHelper,
      },
    }
  },
}
</script>
