<template>
  <v-card>
    <!-- Top Action: Navigate to Chat(Not in admin view) -->
    <div
      v-if="menuItemTopAction"
      class="pa-5"
    >
      <v-list-item
        link
        :to="menuItemTopAction.route"
      >
        <v-btn
          outlined
          block
          color="primary"
        >
          <v-icon size="22">
            {{ menuItemTopAction.icon }}
          </v-icon>
          <span class="ml-2">{{ menuItemTopAction.title }}</span>
        </v-btn>
      </v-list-item>
    </div>

    <v-card-title>
      <!-- Search &amp; Filter -->
      {{ $t('ProductFilters.title') }}
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="secundary"
        @click="clearFilters"
      >
        Clear
      </v-btn>
    </v-card-title>

    <v-row class="px-2 ma-0">
      <!-- User filter -->
      <v-col
        v-if="(isAdminView && userFilterOptions !== undefined)"
        cols="12"
      >
        <v-select
          v-model="userFilterLocal"
          :label="$t('ContactFilters.user')"
          :items="userFilterOptions"
          item-text="firstName"
          return-object
          outlined
          clearable
          dense
          chips
          small-chips
          hide-details
          color="primary"
          @input="(item) => $emit('update:userFilter', item)"
        ></v-select>
      </v-col>

      <!-- Contact Status Select -->
      <v-col
        v-if="contactStatusFilterOptions"
        cols="12"
      >
        <!-- Return object(value: String, text: String) -->
        <v-select
          v-model="contactStatusFilterLocal"
          :label="$t('ContactFilters.status')"
          :items="contactStatusFilterOptions"
          item-text="text"
          item-value="value"
          outlined
          clearable
          dense
          chips
          small-chips
          hide-details
          :multiple="true"
          color="primary"
          @input="(item) => $emit('update:contactStatusFilter', item)"
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import useRouterPath from '@/router/useRouterPath'

export default {
  props: {
    isAdminView: {
      type: Boolean,
      default: false,
    },

    // Filter: User (Admin view)
    userFilter: {
      type: Object,
      required: false,
      default: null,
    },
    userFilterOptions: {
      type: Array,
      required: false,
      default: () => undefined, // Solo queremos que se visualize si se inicializa desde el padre
    },

    // Filter: Status (Array con la lista de estados) ['new', 'irrelevant', ...]
    contactStatusFilter: {
      type: Array,
      required: false,
      default: null,
    },
    contactStatusFilterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { menuItemChat } = useRouterPath()

    // Admin view is not navegable
    const menuItemTopAction = ref(null)
    if (!props.isAdminView) {
      menuItemTopAction.value = menuItemChat.value
    }

    // Contact Status
    const contactStatusFilterArrayLocal = ref([])
    const contactStatusFilterLocal = ref(JSON.parse(JSON.stringify(props.contactStatusFilter)))
    const userFilterLocal = ref(JSON.parse(JSON.stringify(props.userFilter)))

    const calculateCountFiltering = () => {
      const count = (contactStatusFilterLocal.value ? 1 : 0)
      + (userFilterLocal.value ? 1 : 0)
      + (contactStatusFilterArrayLocal.length)

      return count
    }

    // Este watch solo es necesario si el filtro se duplica (Por ejemplo crear 2 componentes)
    watch(
      [
        () => props.userFilter,
        () => props.contactStatusFilter,
      ],
      () => {
        userFilterLocal.value = JSON.parse(JSON.stringify(props.userFilter))
        contactStatusFilterLocal.value = JSON.parse(JSON.stringify(props.contactStatusFilter))

        emit('update:countFiltering', calculateCountFiltering())
      },
    )

    // Clear all filters
    const clearFilters = () => {
      userFilterLocal.value = null
      emit('update:userFilter', userFilterLocal.value)

      contactStatusFilterLocal.value = null
      emit('update:contactStatusFilter', contactStatusFilterLocal.value)
    }

    return {
      // Events
      clearFilters,
      menuItemTopAction, // Link

      // Filter
      userFilterLocal,
      contactStatusFilterLocal,
    }
  },
}
</script>
