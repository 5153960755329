<template>
  <v-card class="rounded-0 app-marketplace h-full position-relative overflow-hidden text-sm">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      fixed-tabs
      :hide-slider="false"
      icons-and-text
      centered
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
        center-active
      >
        <span class="d-none d-sm-block">{{ tab.title }}</span>
        <v-icon>
          {{ tab.icon }}
        </v-icon>
      </v-tab>
    </v-tabs>

    <!-- tabs item: evitamos swipe para porder hacer scroll en las listas con el mobil -->
    <v-tabs-items
      v-model="tab"
      class="h-full"
      touchless
    >
      <v-tab-item>
        <company-settings-account
          v-if="companyLocal"
          :company-general-data="companyLocal.general"
        ></company-settings-account>
      </v-tab-item>

      <v-tab-item>
        <company-settings-address
          v-if="companyLocal"
          :company-address-data="companyLocal.address"
        ></company-settings-address>
      </v-tab-item>

      <v-tab-item>
        <company-user-list></company-user-list>
      </v-tab-item>

      <v-tab-item
        v-if="isCompanyProfileSeller"
        class="h-full"
      >
        <ProductSupplierList :is-admin-view="true"></ProductSupplierList>
      </v-tab-item>

      <v-tab-item
        v-if="isCompanyProfilePurchaser"
        class="h-full"
      >
        <ProductDemanderList :is-admin-view="true"></ProductDemanderList>
      </v-tab-item>

      <v-tab-item class="h-full">
        <ContactList :is-admin-view="true"></ContactList>
      </v-tab-item>

      <v-tab-item>
        <CompanyWebview
          v-if="companyLocal"
          :webview-data="companyLocal.webview"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiHomeAccount, mdiMapOutline, mdiAccountGroupOutline, mdiEarth, mdiViewGrid,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import store from '@/store'

// Components
import CompanySettingsAccount from './CompanySettingsAccount.vue'
import CompanySettingsAddress from './CompanySettingsAddress.vue'
import CompanyUserList from '../company-user-list/CompanyUserList.vue'
import CompanyWebview from '../company-webview/CompanyWebview.vue'
import ProductSupplierList from '../../product/product-supplier/product-supplier-list/ProductSupplierList.vue'
import ProductDemanderList from '../../product/product-demander/product-demander-list/ProductDemanderList.vue'
import ContactList from '../../chat/contact-list/ContactList.vue'

// Services
import useCompany from '@/services/api/modules/company/useCompany'

export default {
  components: {
    CompanySettingsAccount,
    CompanySettingsAddress,
    CompanyUserList,
    CompanyWebview,
    ProductSupplierList,
    ProductDemanderList,
    ContactList,
  },
  setup() {
    const companyLocal = ref(null)

    const blackAddress = {
      country: '',
      region: '',
      city: '',
      zip: '',
      street: '',
    }

    // Seller and Purchaser profiles
    const isCompanyProfileSeller = ref(store.state.company.seller) // store.state.company.seller
    const isCompanyProfilePurchaser = ref(store.state.company.purchaser) // store.state.company.purchaser

    // Tabs
    // Solo indicamos las tabs compatibles con el role de la empresa
    const tab = ref('')
    const tabs = ref([
      { title: 'Company', icon: mdiHomeAccount },
      { title: 'Address', icon: mdiMapOutline },
      { title: 'Users', icon: mdiAccountGroupOutline },

      // Optional
      // { title: 'Catalog', icon: mdiViewGrid }, // Seller
      // { title: 'Interest', icon: mdiViewGrid }, // Purchaser
      // { title: 'WebView', icon: mdiEarth },
    ])
    if (isCompanyProfileSeller.value) {
      tabs.value.push({ title: 'Catalog', icon: mdiViewGrid })
    }
    if (isCompanyProfilePurchaser.value) {
      tabs.value.push({ title: 'Interest', icon: mdiViewGrid })
    }
    tabs.value.push({ title: 'Contact', icon: mdiAccountGroupOutline })
    tabs.value.push({ title: 'WebView', icon: mdiEarth })

    // Fetch Company info
    const fetchCompany = async () => {
      await useCompany.read()
        .then(res => {
          const { companyData } = res.data

          // Checking... Address no es obligatorio => Indicamos el objeto addres para preveer crearla por el usuario
          if (!companyData.address || companyData.address === undefined || companyData.address.length === 0) {
            companyData.address = blackAddress // Object.assign(companyData.address, address)
          }
          companyLocal.value = companyData

          // No actualizamos los indicadores ya han sido renderizados
          // isCompanyProfileSeller.value = companyData?.general?.seller
          // isCompanyProfilePurchaser.value = companyData?.general?.purchaser
        })
    }
    fetchCompany()

    return {
      // Tab control
      tab,
      tabs,

      // Data
      companyLocal,
      isCompanyProfileSeller,
      isCompanyProfilePurchaser,
    }
  },
}
</script>

<!-- Global CSS: Eliminar espacio de flechas en el Tab Menu cuando el espacio es insuficiente -->
<style lang="scss">

  // v-slide-group__prev, .v-slide-group__next
  .v-slide-group {
    &__prev,
    &__next {
      display: none !important;
    }
  }
</style>
