// Notification
import { mdiChat } from '@mdi/js'
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
import { resolveContactStatus, contactStatusOptions } from '@/services/master/ContactStatus'
import { resolveUserStatus, userStatusOptions } from '@/services/master/UserStatus'

export default function useChatHandler() {
  const toast = useToast()

  const addNewChatRoomUser = contact => ({
    id: null,
    createdAt: new Date(new Date().getTime()),
    userId: null,
    chatRoom: null,
    contact,

    unseenMsgs: 0,
    lastMessageRead: null,
    lastMessage: null,
  })

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendChatNotification = (title, variant = TYPE.ERROR, icon = mdiChat) => {
    // toast.warning(`Campaign Inbound ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `Chat - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER, type: variant },
    )
  }

  return {
    // Chat
    addNewChatRoomUser,

    // UI
    sendChatNotification,

    // User
    userStatusOptions,
    resolveUserStatus,

    // Contact
    contactStatusOptions,
    resolveContactStatus,
  }
}
