<template>
  <v-card>
    <v-card-title>
      {{ $t('CompanySettings.body.tab4.users.title') }}
    </v-card-title>

    <!-- users  -->
    <v-card-text
      v-for="(item, index) in usersData"
      :key="index"
      class="d-flex justify-content-start align-items-center"
      :class="index == 0 ? 'mt-2' : 'mt-1'"
    >
      <!-- Avatar + Firstname and username -->
      <div class="d-flex align-center">
        <v-avatar
          :color="item.avatar ? '' : 'primary'"
          :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
          size="40"
        >
          <v-img
            v-if="item.avatar"
            :src="item.avatar"
          ></v-img>
          <span
            v-else
            class="font-weight-medium"
          >{{ avatarText(item.firstName) }}</span>
        </v-avatar>

        <div class="d-flex flex-column ms-3">
          <router-link
            :to="{ name : 'apps-user-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.firstName }}
          </router-link>
          <small>{{ item.username }}</small>
        </div>
      </div>

      <div class="ml-auto">
        <!-- Role icon -->
        <v-avatar
          size="30"
          :color="resolveUserRoleVariant(item.companyRole)"
          :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.companyRole)}--text me-3`"
        >
          <v-icon
            size="18"
            :color="resolveUserRoleVariant(item.companyRole)"
          >
            {{ resolveUserRoleIcon(item.companyRole) }}
          </v-icon>
        </v-avatar>

        <!-- Seller Profile-->
        <v-avatar
          size="30"
          :color="resolveUserIsSellerVariantAndIcon(item.seller).variant"
          :class="`v-avatar-light-bg ${resolveUserIsSellerVariantAndIcon(item.seller).variant}--text me-3`"
        >
          <v-icon
            size="18"
            :color="resolveUserIsSellerVariantAndIcon(item.seller).variant"
          >
            {{ resolveUserIsSellerVariantAndIcon(item.seller).icon }}
          </v-icon>
        </v-avatar>

        <!-- Purchaser Profile -->
        <v-avatar
          size="30"
          :color="resolveUserIsPurchaserVariantAndIcon(item.purchaser).variant"
          :class="`v-avatar-light-bg ${resolveUserIsPurchaserVariantAndIcon(item.purchaser).variant}--text me-3`"
        >
          <v-icon
            size="18"
            :color="resolveUserIsPurchaserVariantAndIcon(item.purchaser).variant"
          >
            {{ resolveUserIsPurchaserVariantAndIcon(item.purchaser).icon }}
          </v-icon>
        </v-avatar>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'

// Services
import useChatHandler from '@/views/apps/chat/useChatHandler'
import useCompanyHandler from '../useCompanyHandler'

export default {
  props: {
    usersData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    // Role colors, Seller and purchaser colors
    const {
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserIsSellerVariantAndIcon,
      resolveUserIsPurchaserVariantAndIcon,
    } = useCompanyHandler()

    const { resolveAvatarBadgeVariant } = useChatHandler()

    return {
      // UI
      avatarText,
      resolveAvatarBadgeVariant,

      // User resolve
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserIsSellerVariantAndIcon,
      resolveUserIsPurchaserVariantAndIcon,
    }
  },
}
</script>
