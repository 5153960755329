<template>
  <v-card>
    <v-card-title class="d-flex">
      <span>{{ productSupplier.name }}</span>
      <v-spacer></v-spacer>
      <v-chip
        small
        :color="resolveProductSupplierStatus(productSupplier.status).color"
        :class="`${resolveProductSupplierStatus(productSupplier.status).color}--text`"
        class="v-chip-light-bg font-weight-semibold text-capitalize text-right"
      >
        {{ productSupplier.status }}
      </v-chip>
    </v-card-title>
    <v-divider></v-divider>

    <!-- ProductSupplierCard -->
    <v-card-text>
      <ProductSupplierCard :product-supplier="productSupplier" />
    </v-card-text>

    <!-- Conservation / Presentation -->
    <v-card-text class="d-flex flex-row align-center">
      <span class="text-nowrap font-weight-bold">
        {{ productSupplier.productConservation.name }}
      </span>
      <v-spacer></v-spacer>
      <span class="text-nowrap font-weight-bold">
        {{ productSupplier.productPresentation.name }}
      </span>
    </v-card-text>

    <!-- Name & Description -->
    <v-divider class="mx-4"></v-divider>
    <!-- Customer Tags -->
    <v-card-text class="d-flex flex-row align-center">
      <div class="badge-wrapper">
        <v-chip
          v-for="tag in productSupplier.tags"
          :key="tag"
          small
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ tag }}
        </v-chip>
      </div>
    </v-card-text>
    <v-card-text>
      <div class="d-flex flex-column">
        <span>{{ productSupplier.name }}</span>
        <!-- style="max-width: 200px;" -->
        <small
          class="text-truncate text-muted"
          style="height: 24px;"
        >
          {{ filterTags(productSupplier.description) }}
        </small>
      </div>
    </v-card-text>

    <!-- Actions -->
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        text
        @click.stop="$emit('on-action-edit', productSupplier)"
      >
        <v-icon>
          {{ icons.mdiFileEdit }}
        </v-icon>
        <!-- {{ buttonTextCancel }} -->
        Editar
      </v-btn>

      <!-- @click="$emit('update:is-dialog-active',false); onDelete()" -->
      <!-- @click="$emit('update:is-dialog-active',false); $emit('delete',id)" -->
      <v-btn
        color="error"
        outlined
        text
        @click.stop="$emit('on-action-delete', productSupplier)"
      >
        <v-icon>
          {{ icons.mdiDelete }}
        </v-icon>
        <!-- {{ buttonText }} -->
        Eliminar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mdiDelete, mdiFileEdit } from '@mdi/js'

// UX
import { filterTags } from '@core/utils/filter'

// Components
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'

// Services
import useProductSupplierHandler from '../useProductSupplierHandler'

export default {
  components: {
    ProductSupplierCard,
  },
  props: {
    productSupplier: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // Role colors, Seller and purchaser colors
    const { resolveProductSupplierStatus } = useProductSupplierHandler()

    return {
      // UI
      filterTags,
      resolveProductSupplierStatus,

      // icons
      icons: {
        mdiFileEdit,
        mdiDelete,
      },
    }
  },
}
</script>
