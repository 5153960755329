<template>
  <v-card class="rounded-0 app-product-demander h-full position-relative overflow-hidden text-sm">
    <!-- Add or edit -->
    <ProductDemanderHandleSidebar
      v-model="isProductDemanderHandlerSidebarActive"
      :product-demander="productDemanderSelected"
      :is-admin-view="isAdminView"
      :demander-filter-options="demanderFilterOptions"
      :product-category-filter-options="productCategoryFilterOptions"
      :product-filter-options="productFilterOptions"
      :product-conservation-filter-options="productConservationFilterOptions"
      :product-presentation-filter-options="productPresentationFilterOptions"
      :clear-product-demander-data="clearProductDemanderData"
      @refetch-data="fetchProductDemanders"
    ></ProductDemanderHandleSidebar>

    <!-- Delete -->
    <DialogDelete
      :id="productDemanderDeleteId"
      v-model="isProductDemanderDeleteDialogActive"
      :title="$t('ProductDemanderList.table.dialog_delete.title')"
      :text="$t('ProductDemanderList.table.dialog_delete.text')"
      :button="$t('ProductDemanderList.table.dialog_delete.button_text')"
      @delete="onProductDemanderDelete"
    ></DialogDelete>

    <!-- Ask for Reasign Demander -->
    <ProductDemanderReasignDialog
      v-model="isProductDemanderReasignDialogActive"
      :demander-filter-options="demanderFilterOptions"
      @reasign="(demander_id) => onProductDemanderReasign(demander_id)"
    ></ProductDemanderReasignDialog>

    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <ProductFilters
        sm-cols="12"
        :is-admin-view="isAdminView"
        :count-filtering.sync="countFiltering"
        :is-marketplace-available-filter-enabled.sync="isMarketplaceAvailableFilterEnabled"
        :is-marketplace-available-filter.sync="isMarketplaceAvailableFilter"
        :is-campaign-receive-available-filter-enabled.sync="isCampaignReceiveAvailableFilterEnabled"
        :is-campaign-receive-available-filter.sync="isCampaignReceiveAvailableFilter"
        :demander-filter.sync="demanderFilter"
        :demander-filter-options="demanderFilterOptions"
        :product-category-filter.sync="productCategoryFilter"
        :product-category-filter-options="productCategoryFilterOptions"
        :product-filter.sync="productFilter"
        :product-filter-options="productFilterOptions"
        :product-conservation-filter.sync="productConservationFilter"
        :product-conservation-filter-options="productConservationFilterOptions"
        :product-presentation-filter.sync="productPresentationFilter"
        :product-presentation-filter-options="productPresentationFilterOptions"
      />
    </v-navigation-drawer>

    <!-- Content Area -->
    <div
      class="h-full position-relative"
      :style="contentStyles"
    >
      <!-- Top Actions -->
      <!-- flex-wrap: Se puede romper líneas; flex-nowrap: Misma linea -->
      <div class="d-md-flex align-center justify-space-between flex-wrap mt-4 px-2">
        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <div
            v-if="isTableVisible"
            class="d-flex align-center"
          >
            <!-- Multiple: Custom Actions -->
            <div
              v-if="selectedProductDemanders.length"
              class="ml-2"
            >
              <!-- Reasignar comprador -->
              <v-btn
                small
                outlined
                class="me-2"
                :color="customActionReasign.color"
                @click.stop="askProductDemanderReasign()"
              >
                <v-icon size="22">
                  {{ customActionReasign.icon }}
                </v-icon>
                <span>{{ customActionReasign.title }}</span>
              </v-btn>
            </div>
          </div>

          <!-- Menu View Filter Left Side -->
          <FilterButton
            :count-filtering="countFiltering"
            :is-left-sidebar-open.sync="isLeftSidebarOpen"
            class="mr-2"
          ></FilterButton>

          <!-- SortBy and OrderBy TODO: Pasar sortBy como array (Es el elemento soportado v-data-table)-->
          <TableSortExternal
            :sort-by.sync="options.sortBy[0]"
            :sort-desc.sync="options.sortDesc[0]"
            :table-columns="tableColumns"
          ></TableSortExternal>

          <!-- Table Vs GridCard view -->
          <TableToggleView
            :is-table-visible.sync="isTableVisible"
            class="ml-2"
          ></TableToggleView>
        </div>

        <!-- Break if small -->
        <v-divider
          v-if="$vuetify.breakpoint.smAndDown"
          class="break mt-4 mb-2"
        ></v-divider>

        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <!-- search -->
          <v-text-field
            v-model="searchQueryFilter"
            :placeholder="$t('ProductDemanderList.table.search')"
            outlined
            small
            hide-details
            clearable
            class="product-demander-search-input"
            :prepend-inner-icon="icons.mdiMagnify"
          ></v-text-field>

          <!-- <v-spacer></v-spacer> -->
          <v-btn
            color="primary"
            outlined
            small
            @click="onAddNewProductDemanderClick"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span class="d-none d-md-block">{{ $t('ProductDemanderList.table.buttom_add') }}</span>
          </v-btn>
        </div>
      </div>

      <!-- Table System -->
      <div v-show="isTableVisible">
        <!-- Options ya resuelve todos estos parametros que cambian
          :items-per-page="options.itemsPerPage"
          :page.sync="options.page"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
        -->
        <v-data-table
          ref="refTable"
          v-model="selectedProductDemanders"
          :headers="tableColumns"
          :items="productDemanders"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :hide-default-footer="true"
          :footer-props="{
            'items-per-page-options': itemsPerPageOptions
          }"
          :loading="loading"
          :show-select="isAdminView"
          :single-select="false"
          mobile-breakpoint="200"
          @click:row="(item) => onHandleProductDemanderClick(item)"
        >
          <!-- Demander: demander.user -->
          <template #[`item.user.firstName`]="{item}">
            <span>{{ item.demander.user.firstName }}</span>
          </template>

          <!-- Name & Description -->
          <template #[`item.name`]="{item}">
            <div class="d-flex flex-column">
              <span>{{ item.name }}</span>
              <small
                class="text-truncate text-muted"
                style="max-width: 200px;"
              >
                {{ filterTags(item.description) }}
              </small>
            </div>
          </template>

          <!-- Product / Category-->
          <template #[`item.product.name`]="{item}">
            <ProductDemanderCard :product-demander="item" />
          </template>

          <!-- Column: ProductConservation -->
          <template #[`item.productConservation.name`]="{item}">
            <span class="text-nowrap">
              {{ item.productConservation.name }}
            </span>
          </template>

          <!-- Column: productPresentation -->
          <template #[`item.productPresentation.name`]="{item}">
            <span class="text-nowrap">
              {{ item.productPresentation.name }}
            </span>
          </template>

          <template #[`item.flags`]="{item}">
            <div class="d-flex flex-nowrap align-center justify-center">
              <!-- Marketplace navegable ? (Solo para la vista NO ADMIN) -->
              <ProductDemanderMarketplaceNavegableIcon
                :product-demander="item"
                :is-navegable="!isAdminView"
              />
              <ProductDemanderCampaignInboundNavegableIcon :product-demander="item" />
            </div>
          </template>

          <!-- actions -->
          <template #[`item.actions`]="{item}">
            <div class="d-flex flex-nowrap align-center justify-space-between">
              <!-- Marketplace navegable ? -->
              <!-- <ProductDemanderMarketplaceNavegableIcon
                v-if="!isAdminView"
                :product-demander="item"
                :is-navegable="true"
              ></ProductDemanderMarketplaceNavegableIcon> -->

              <!-- Open -->
              <v-icon
                size="20"
                @click.stop="onHandleProductDemanderClick(item)"
              >
                {{ icons.mdiFileEdit }}
              </v-icon>

              <!-- Delete -->
              <v-icon
                class="ml-2"
                size="20"
                color="error"
                @click.stop="askProductDemanderDelete(item.id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </div>
          </template>
        </v-data-table>
        <v-divider></v-divider>
      </div>
      <!-- // END Table System -->

      <!-- Grid System: Table Card -->
      <div v-if="!isTableVisible">
        <!-- Loading indicator -->
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
          class="position-absolute"
        />
        <v-divider></v-divider>

        <v-row
          dense
          class="row-container pt-4"
        >
          <v-col
            v-for="productDemander in productDemanders"
            :key="productDemander.id"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-hover
              #default="{ hover: isHovered }"
            >
              <div
                :class="[{'hovered elevation-3': isHovered}]"
                class="grid-box cursor-pointer"
                @click="onHandleProductDemanderClick(productDemander)"
              >
                <ProductDemanderGridCard
                  :product-demander="productDemander"
                  :is-admin-view="isAdminView"
                  @on-action-edit="(item) => onHandleProductDemanderClick(item)"
                  @on-action-delete="(item) => askProductDemanderDelete(item.id)"
                />
              </div>
            </v-hover>
          </v-col>
        </v-row>
      </div>
      <!-- // Grid System: Table Card -->

      <!-- Footer: v-data-footer: Pagination -->
      <!-- Pagination: Usamos options porque está referenciada con v-data-table -->
      <TablePagination
        :options.sync="options"
        :items-length="serverItemsLength"
        :items-per-page-options="itemsPerPageOptions"
      ></TablePagination>
      <!-- // Footer -->
    </div>
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline, mdiFileEdit, mdiMagnify, mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// UX
// import { getVuetify } from '@core/utils'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { avatarText, filterTags, formatDateToMonthShort } from '@core/utils/filter'

// Components
// import ProductFiltersSidebar from '../../ProductFiltersSidebar.vue'
import ProductDemanderCard from '@/components/Cards/ProductDemanderCard.vue'
import DialogDelete from '@/components/DialogDelete.vue'
import FilterButton from '@/components/FilterButton.vue'
import TablePagination from '@/components/TablePagination.vue'
import TableSortExternal from '@/components/TableSortExternal.vue'
import TableToggleView from '@/components/TableToggleView.vue'
import ProductFilters from '../../ProductFilters.vue'
import ProductDemanderGridCard from './ProductDemanderGridCard.vue'
import ProductDemanderHandleSidebar from './ProductDemanderHandleSidebar.vue'
import ProductDemanderReasignDialog from './ProductDemanderReasignDialog.vue'

import ProductDemanderCampaignInboundNavegableIcon from '@/components/NavegableIcon/ProductDemanderCampaignInboundNavegableIcon.vue'
import ProductDemanderMarketplaceNavegableIcon from '@/components/NavegableIcon/ProductDemanderMarketplaceNavegableIcon.vue'

// UI Services
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Services
import useProductDemander from '@/services/api/modules/productDemander'
import useProductMasterData from '../../useProductMasterData'
import useProductDemanderList from './useProductDemanderList'

// Services
import useProductDemanderHandler from '../useProductDemanderHandler'

// Other services
import useDemander from '@/services/api/modules/demander'

export default {
  components: {
    ProductFilters,
    DialogDelete,
    ProductDemanderHandleSidebar,
    ProductDemanderCard,
    ProductDemanderGridCard,
    ProductDemanderReasignDialog,
    TableSortExternal,
    TableToggleView,
    TablePagination,
    FilterButton,

    ProductDemanderMarketplaceNavegableIcon,
    ProductDemanderCampaignInboundNavegableIcon,
  },
  props: {
    isAdminView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // const PRODUCT_DEMANDER_APP_STORE_MODULE_NAME = 'app-product-demander'
    // // Register module
    // if (!store.hasModule(PRODUCT_DEMANDER_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_DEMANDER_APP_STORE_MODULE_NAME, productDemanderStoreModule)
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(PRODUCT_DEMANDER_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_DEMANDER_APP_STORE_MODULE_NAME)
    // })
    // const $vuetify = getVuetify()
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 })

    // if smAndDown =>  Close Chat & Contacts left sidebar
    // const isProductFiltersSidebarActive = ref($vuetify.breakpoint.lg)
    // const isProductFiltersSidebarActive = ref(false)

    // Role colors, Seller and purchaser colors
    const { sendProductDemanderNotification } = useProductDemanderHandler()

    // Admin view (Company Users)
    // const fetchCompanyUsers = (...args) => useUserCompany.fetchUserCompanies(...args)
    const demanderFilterOptions = ref([])
    const fetchDemanders = (...args) => useDemander.fetchDemanders(...args)
    if (props.isAdminView) {
      fetchDemanders().then(response => {
        demanderFilterOptions.value = response.data.demanders
      })
    }

    // const isMarketplaceAvailableFilterEnabled = ref(false)
    // const isMarketplaceAvailableFilter = ref(true)
    // const isCampaignReceiveAvailableFilterEnabled = ref(false)
    // const isCampaignReceiveAvailableFilter = ref(true)

    // Filter Options: Category, Product, ProductConservation, ProductPresentation
    const { productCategories: productCategoryFilterOptions, fetchProductCategories } = useProductMasterData()
    fetchProductCategories().then(response => {
      productCategoryFilterOptions.value = response.data.productCategories
    })

    const { products: productFilterOptions, fetchProducts } = useProductMasterData()
    fetchProducts().then(response => {
      productFilterOptions.value = response.data.products
    })

    const { productConservations: productConservationFilterOptions, fetchProductConservations } = useProductMasterData()
    fetchProductConservations().then(response => {
      productConservationFilterOptions.value = response.data.productConservations
    })

    const { productPresentations: productPresentationFilterOptions, fetchProductPresentations } = useProductMasterData()
    fetchProductPresentations().then(response => {
      productPresentationFilterOptions.value = response.data.productPresentations
    })

    // Table (productDemanders)
    const {
      // Table
      refTable,
      tableColumns,
      options,
      itemsPerPageOptions,

      loading,
      selectedProductDemanders,
      productDemanders,
      serverItemsLength,

      // Fetch data
      fetchProductDemanders,

      // Filters
      searchQueryFilter,

      // ProductDemander Flags filters
      isMarketplaceAvailableFilterEnabled,
      isMarketplaceAvailableFilter,
      isCampaignReceiveAvailableFilterEnabled,
      isCampaignReceiveAvailableFilter,

      // Filters: Status, Category, Product, ProductConservation, ProductPresentation
      countFiltering,
      demanderFilter,
      productCategoryFilter,
      productFilter,
      productConservationFilter,
      productPresentationFilter,
    } = useProductDemanderList(props.isAdminView)

    // ————————————————————————————————————
    //* ——— ProductDemanders Selection
    // ————————————————————————————————————
    // CUSTOM ACTIONS
    const { customActionReasign } = useProductDemanderHandler()

    // Reasign with dialog form
    const isProductDemanderReasignDialogActive = ref(false)
    const askProductDemanderReasign = () => {
      isProductDemanderReasignDialogActive.value = true
    }
    const onProductDemanderReasign = (demanderId, productDemandersToReasign = selectedProductDemanders.value) => {
      const tasks = productDemandersToReasign.map(productDemander => useProductDemander.reasignProductDemander(productDemander.id, demanderId))
      Promise.all(tasks).finally(() => {
        fetchProductDemanders()
        selectedProductDemanders.value = []

        sendProductDemanderNotification('Product Reasigned', 'success')
      })
    }

    // Table Vs Card list
    // const isTableVisible = ref(true)
    const { isTableVisibleProductDemander: isTableVisible } = useAppConfigUser()

    // Create productDemander by Sidebar
    const isProductDemanderHandlerSidebarActive = ref(false)
    const isProductDemanderDeleteDialogActive = ref(false)
    const blankProductDemander = {
      id: null,

      // Indicadores
      isMarketplaceAvailable: true,
      isCampaignReceiveAvailable: true,

      name: '',
      description: '',
      createdAt: new Date(),

      // Object complete
      demander: null, // Asignado por el servidor
      productCategory: null,
      product: null,
      productConservation: null,
      productPresentation: null,
    }

    const productDemanderSelected = ref(JSON.parse(JSON.stringify(blankProductDemander)))
    const clearProductDemanderData = () => {
      productDemanderSelected.value = JSON.parse(JSON.stringify(blankProductDemander))
    }

    const onAddNewProductDemanderClick = () => {
      productDemanderSelected.value = blankProductDemander
      isProductDemanderHandlerSidebarActive.value = true
    }

    const onHandleProductDemanderClick = item => {
      // .stop prevent no funciona
      let productDemanderData
      if (item instanceof Array) {
        // eslint-disable-next-line prefer-destructuring
        productDemanderData = item[0]
      } else {
        productDemanderData = item
      }

      // Checking...
      if (productDemanderData) {
        productDemanderSelected.value = { ...blankProductDemander, ...productDemanderData }

        // productDemander.value = _.merge({}, blankProductDemander, productDemanderData)
        isProductDemanderHandlerSidebarActive.value = true
      }
    }

    // watch(isProductDemanderHandlerSidebarActive, () => {
    //   if (!isProductDemanderHandlerSidebarActive.value) {
    //     // refTable.value.clearSelected()
    //   }
    // })

    // TODO Not in use (Se añaden deltro del Handle Sidebar)
    const addProductDemander = productDemanderData => {
      useProductDemander.createproductDemander(productDemanderData).then(() => {
        fetchProductDemanders()
      })
    }

    // TODO Not in use (Se añaden deltro del Handle Sidebar)
    const updateProductDemander = productDemanderData => {
      useProductDemander.updateProductDemander(productDemanderData).then(() => {
        fetchProductDemanders()
      })
    }

    // TODO Actualizamos la tabla pero seguimos con el formulario de edición abierto
    const updateProductDemanderImage = productDemanderImage => {
      console.log(productDemanderImage)
      fetchProductDemanders()
    }

    // const removeproductDemander = productDemanderId => {
    //   useProductDemander.removeproductDemander(productDemanderId)
    //     .then(() => {
    //       sendProductDemanderNotification('User Deleted', 'success')
    //       fetchProductDemanders()
    //     })
    // }

    // Delete with dialog ask
    const productDemanderDeleteId = ref('')
    const askProductDemanderDelete = productDemanderId => {
      isProductDemanderDeleteDialogActive.value = true
      productDemanderDeleteId.value = productDemanderId
    }

    const onProductDemanderDelete = productDemanderId => {
      useProductDemander
        .removeProductDemander(productDemanderId, props.isAdminView)
        .then(() => {
          sendProductDemanderNotification('Product Deleted', 'success')
          fetchProductDemanders()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            sendProductDemanderNotification(error.response.data.error.message, 'error')
          }
        })
    }

    return {
      // Table
      refTable,
      tableColumns,
      options,
      itemsPerPageOptions,

      loading,
      selectedProductDemanders,
      productDemanders,
      serverItemsLength,

      // Table selection
      customActionReasign,
      isProductDemanderReasignDialogActive,
      askProductDemanderReasign,
      onProductDemanderReasign,

      // Table Vs Grid card
      isLeftSidebarOpen,
      isTableVisible,
      contentStyles,

      // Fetch data
      fetchProductDemanders,

      // Filters
      searchQueryFilter,

      // Filters(Custom): Status, Category, Product, ProductConservation, ProductPresentation
      countFiltering,
      isMarketplaceAvailableFilterEnabled,
      isMarketplaceAvailableFilter,
      isCampaignReceiveAvailableFilterEnabled,
      isCampaignReceiveAvailableFilter,
      demanderFilter,
      demanderFilterOptions,
      productFilter,
      productFilterOptions,
      productCategoryFilter,
      productCategoryFilterOptions,
      productConservationFilter,
      productConservationFilterOptions,
      productPresentationFilter,
      productPresentationFilterOptions,

      // CRUD Insert and Update
      isProductDemanderHandlerSidebarActive,
      productDemanderSelected, // Selected item
      onAddNewProductDemanderClick,
      onHandleProductDemanderClick,
      addProductDemander,
      updateProductDemander,
      updateProductDemanderImage,
      clearProductDemanderData,

      // CRUD Delete
      isProductDemanderDeleteDialogActive,
      productDemanderDeleteId,
      askProductDemanderDelete,
      onProductDemanderDelete,

      // UI
      filterTags,
      avatarText,
      formatDateToMonthShort,

      // icons
      icons: {
        mdiMagnify,
        mdiFileEdit,
        mdiDeleteOutline,
        mdiPlus,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
/* Inserting this collapsed row between two flex items will make
  * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

.grid-box {
  border-radius: 5px;
  border: 1px solid rgba(94, 86, 105, 0.14);
  // background: lightgray;
}
// ROW/COLS
.row-container {
  margin-left: 8px;
  margin-right: 8px;
}
.row-container > * {
  // max-width: 300px;
}
</style>

<style lang="scss">
.app-product-demander {
  // Style search input
  .product-demander-search-input {
    .v-input__slot {
      fieldset {
        border: none !important;
      }
    }
  }
}
</style>
