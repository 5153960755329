import {
  mdiAccountOutline,
  mdiBasketOutline,
  mdiCheckCircleOutline,
  mdiClose,
  mdiDnsOutline,
  mdiInformation,
  mdiPencilOutline,
  mdiShield,
  mdiShopping,
} from '@mdi/js'

// import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

import { userRoleList } from '@/services/master/UserRole'
import { userStatusOptions, resolveUserStatus } from '@/services/master/UserStatus'

export default function useCompanyModule() {
  // Esto es para traducir las constantes (Textos fijos como Roles, Estados, ...)
  // const { t, te } = useI18nUtils()
  const toast = useToast()

  //   const addNewCompanyUser = () => ({
  //     // id: null,
  //     // createdAt: new Date(new Date().getTime()),
  //     // userId: null,
  //     // chatRoom: null,
  //     // contact,

  //     firstName: '',
  //     username: '',
  //     companyRole: 'staff',
  //     seller: false,
  //     purchaser: false,
  //   })

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendCompanyNotification = (title, variant = TYPE.ERROR, icon = mdiShield) => {
    // toast.warning(`Campaign Inbound ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `CompanyUser - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER, type: variant },
    )
  }

  // Translate UserRole options
  // User.Fields.company_role-options.admin ==> Admin
  // User.Fields.company_role-options.staff ==> Staff
  // const userRoleOptions = userRoleList.map(userRole => {
  //   const text = te(`User.Fields.company_role-options.${userRole.value}`) ? t(`User.Fields.company_role-options.${userRole.value}`) : userRole.text

  //   return { value: userRole.value, text }
  // })
  const userRoleOptions = userRoleList.map(userRole => ({ value: userRole.value, text: userRole.text }))

  // Translate UserStatus options
  // User.Fields.status-options.online ==> On line
  // User.Fields.status-options.busy ==> Busy
  // User.Fields.status-options.away ==> Away
  // User.Fields.status-options.offline ==> Off line
  // const userStatusOptions = userStatusList.map(userStatus => ({ value: userStatus.value, text: t(`User.Fields.status-options.${userStatus.value}`) }))
  // const userStatusOptions = userStatusList.map(userStatus => ({ value: userStatus.value, text: userStatus.text }))

  // User: Estado de usuario (Activo|Inactivo)
  const resolveUserEnabledVariantAndIcon = enabled => {
    if (enabled === true) return { variant: 'success', icon: mdiCheckCircleOutline }
    if (enabled === false) return { variant: 'error', icon: mdiInformation }

    return { variant: 'secondary', icon: mdiInformation }
  }

  // Seller => Primary (blue)
  const resolveUserIsSellerVariantAndIcon = value => {
    if (value === true) return { variant: 'primary', icon: mdiBasketOutline }
    if (value === false) return { variant: 'secondary', icon: mdiClose }

    return { variant: 'secondary', icon: mdiClose }
  }

  // Purchaser => Success (green)
  const resolveUserIsPurchaserVariantAndIcon = value => {
    if (value === true) return { variant: 'success', icon: mdiShopping }
    if (value === false) return { variant: 'secondary', icon: mdiClose }

    return { variant: 'secondary', icon: mdiClose }
  }

  const resolveUserRoleVariant = role => {
    if (role === 'admin') return 'error'
    if (role === 'staff') return 'info'

    if (role === 'seller') return 'primary'
    if (role === 'purchaser') return 'success'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'admin') return mdiDnsOutline
    if (role === 'staff') return mdiAccountOutline

    if (role === 'seller') return mdiPencilOutline
    if (role === 'purchaser') return mdiPencilOutline

    return mdiAccountOutline
  }

  return {
    // CompanyUser
    // addNewCompanyUser,

    // UI
    sendCompanyNotification,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserEnabledVariantAndIcon, // User eabled icon and variant
    resolveUserIsSellerVariantAndIcon,
    resolveUserIsPurchaserVariantAndIcon,

    // UI
    resolveUserStatus,

    // Master
    userRoleOptions, // Translated roles
    userStatusOptions, // Traslated status
  }
}
