<template>
  <div id="user-list">
    <CompanyUserHandleSidebar
      v-model="isCompanyUserHandlerSidebarActive"
      :company-user="companyUserSelected"
      @refetch-data="fetchCompanyUsers"
    ></CompanyUserHandleSidebar>

    <DialogDelete
      :id="companyUserDeleteId"
      v-model="isCompanyUserDeleteDialogActive"
      :title="$t('CompanySettings.body.tab3.table.dialog_delete.title')"
      :text="$t('CompanySettings.body.tab3.table.dialog_delete.text')"
      :button="$t('CompanySettings.body.tab3.table.dialog_delete.button_text')"
      @delete="onCompanyUserDelete"
    ></DialogDelete>

    <v-card>
      <!-- Filters -->
      <CompanyUserListFilters
        :role-filter.sync="roleFilter"
        :status-filter.sync="statusFilter"
        :role-options="userRoleOptions"
        :status-options="userStatusOptions"
      />
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('CompanySettings.body.tab3.table.search')"
          outlined
          hide-details
          dense
          class="user-search mr-3"
        >
        </v-text-field>

        <!-- <v-spacer></v-spacer> -->

        <div class="d-flex align-center flex-wrap">
          <!-- @click.stop="isCompanyUserHandlerSidebarActive = !isCompanyUserHandlerSidebarActive" -->
          <v-btn
            color="primary"
            class="mr-0"
            @click="onAddNewCompanyUserClick"
          >
            <v-icon class="d-none d-sm-block">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>{{ $t('CompanySettings.body.tab3.table.buttom_add') }}</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="itemListTable"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        :loading="loading"
        show-select
      >
        <!-- name -->
        <template #[`item.firstName`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.firstName) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'apps-user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.firstName }}
              </router-link>
              <small>{{ item.username }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.companyRole`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.companyRole)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.companyRole)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.companyRole)"
              >
                {{ resolveUserRoleIcon(item.companyRole) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.companyRole }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveUserStatus(item.status).color"
            :class="`${resolveUserStatus(item.status).color}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- Column: Seller -->
        <template #[`item.seller`]="{item}">
          <v-avatar
            size="30"
            :color="resolveUserIsSellerVariantAndIcon(item.seller).variant"
            :class="`v-avatar-light-bg ${resolveUserIsSellerVariantAndIcon(item.seller).variant}--text me-3`"
          >
            <v-icon
              size="18"
              :color="resolveUserIsSellerVariantAndIcon(item.seller).variant"
            >
              {{ resolveUserIsSellerVariantAndIcon(item.seller).icon }}
            </v-icon>
          </v-avatar>
        </template>

        <!-- Column: Purchaser -->
        <template #[`item.purchaser`]="{item}">
          <v-avatar
            size="30"
            :color="resolveUserIsPurchaserVariantAndIcon(item.purchaser).variant"
            :class="`v-avatar-light-bg ${resolveUserIsPurchaserVariantAndIcon(item.purchaser).variant}--text me-3`"
          >
            <v-icon
              size="18"
              :color="resolveUserIsPurchaserVariantAndIcon(item.purchaser).variant"
            >
              {{ resolveUserIsPurchaserVariantAndIcon(item.purchaser).icon }}
            </v-icon>
          </v-avatar>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-icon
            size="20"
            class="me-2"
            @click="onHandleCompanyUserClick(item)"
          >
            {{ icons.mdiFileDocumentOutline }}
          </v-icon>
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="askCompanyUserDelete(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('CompanySettings.body.tab3.table.action_delete') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import store from '@/store'

// Components
import DialogDelete from '@/components/DialogDelete.vue'
import CompanyUserHandleSidebar from './CompanyUserHandleSidebar.vue'
import CompanyUserListFilters from './CompanyUserListFilters.vue'

// Services
import useCompanyHandler from '../useCompanyHandler'

// import companyUserStoreModule from '../companyUserStoreModule'
import useCompanyUserList from './useCompanyUserList'

// Services
import useUserCompany from '@/services/api/modules/userCompany'
import { USER_ROLE } from '@/services/master/UserRole'

export default {
  components: {
    CompanyUserHandleSidebar,
    CompanyUserListFilters,
    DialogDelete,
  },
  setup() {
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    // onUnmounted(() => {
    //  if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    const { seller, purchaser } = store.state.user // Sin watch

    const isCompanyUserHandlerSidebarActive = ref(false)
    const isCompanyUserDeleteDialogActive = ref(false)
    const blankCompanyUser = {
      id: null,
      enabled: false,
      firstName: '',
      username: '',
      companyRole: USER_ROLE.STAFF, // 'staff',
      seller: seller || false,
      purchaser: purchaser || false,
    }
    const companyUserSelected = ref(blankCompanyUser)

    // UserRole Options
    const { userRoleOptions, userStatusOptions, sendCompanyNotification } = useCompanyHandler()

    const onAddNewCompanyUserClick = () => {
      companyUserSelected.value = blankCompanyUser

      // isCompanyUserHandlerSidebarActive.value = !isCompanyUserHandlerSidebarActive.value
      isCompanyUserHandlerSidebarActive.value = true
    }
    const onHandleCompanyUserClick = item => {
      // .stop prevent no funciona
      let companyUserData
      if (item instanceof Array) {
        // eslint-disable-next-line prefer-destructuring
        companyUserData = item[0]
      } else {
        companyUserData = item
      }

      // Checking...
      if (companyUserData) {
        companyUserSelected.value = { ...blankCompanyUser, ...companyUserData }

        // isProductSupplierHandlerSidebarActive.value = true
        isCompanyUserHandlerSidebarActive.value = true
      }
    }

    // Delete with dialog ask
    const companyUserDeleteId = ref('')
    const askCompanyUserDelete = companyUserId => {
      isCompanyUserDeleteDialogActive.value = true
      companyUserDeleteId.value = companyUserId
    }

    const onCompanyUserDelete = companyUserId => {
      useUserCompany
        .removeUserCompany(companyUserId)
        .then(() => {
          sendCompanyNotification('User Deleted', 'success')
          // eslint-disable-next-line no-use-before-define
          fetchCompanyUsers()
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            sendCompanyNotification(error.response.data.error.message, 'error')
          }
        })
    }

    // Table use
    const {
      // Table
      refTable,
      tableColumns,
      searchQuery,
      options,

      loading,
      selectedRows,
      itemListTable,
      serverItemsLength,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatus,
      resolveUserIsSellerVariantAndIcon,
      resolveUserIsPurchaserVariantAndIcon,

      // Data and filters
      fetchCompanyUsers,
      roleFilter,
      statusFilter,

      //   companyId,
    } = useCompanyUserList()

    return {
      // Table
      refTable,
      tableColumns,
      searchQuery,
      options,

      loading,
      selectedRows,
      itemListTable,
      serverItemsLength,

      // UI
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatus,
      resolveUserIsSellerVariantAndIcon,
      resolveUserIsPurchaserVariantAndIcon,

      // Filter with Masters
      roleFilter,
      userRoleOptions,
      statusFilter,
      userStatusOptions,

      // Data
      fetchCompanyUsers,

      // CRUD Create and Update
      isCompanyUserHandlerSidebarActive,
      companyUserSelected,
      onAddNewCompanyUserClick,
      onHandleCompanyUserClick,

      // CRUD Delete
      isCompanyUserDeleteDialogActive,
      companyUserDeleteId,
      askCompanyUserDelete,
      onCompanyUserDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
