<template>
  <!-- <v-card> -->
  <div>
    <v-card-title>
      <!-- Search &amp; Filter -->
      {{ $t('CompanySettings.body.tab3.filters.title') }}
    </v-card-title>
    <!-- <v-row class="px-2 ma-0">
      <v-col
        cols="12"
        sm="4"
      > -->
    <v-card-text class="d-flex align-center flex-wrap">
      <v-select
        v-model="roleFilter"
        :label="$t('CompanySettings.body.tab3.filters.company_role')"
        :items="roleOptions"
        item-text="text"
        item-value="value"
        outlined
        clearable
        dense
        hide-details
        class="mr-3"
        style="max-width: 300px;"
        @input="(item) => $emit('update:roleFilter', item)"
      ></v-select>
      <!-- </v-col>
      <v-col
        cols="12"
        sm="4"
      > -->
      <v-select
        v-model="statusFilter"
        :label="$t('CompanySettings.body.tab3.filters.status')"
        :items="statusOptions"
        item-text="text"
        item-value="value"
        outlined
        dense
        clearable
        hide-details
        class="mr-0"
        style="max-width: 300px;"
        @input="(item) => $emit('update:statusFilter', item)"
      ></v-select>
    </v-card-text>
    <!-- </v-col>
    </v-row> -->
  </div>
</template>

<script>

export default {
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>
