import axios from '@axios'

// import BaseApiService from '../apiBase'

// interface SupplierInterface {
//     id: string;
//     user: User;
//     company: Company;
// }

// # Supplier Router list
// # GET    /suppliers             Retrieves the collection of Supplier resources.
// # POST   /suppliers             Creates a Supplier resource.
// # GET    /suppliers/{id}        Retrieves a Supplier resource.
// # PUT    /suppliers/{id}        Replaces a Supplier resource.
// # DELETE /suppliers/{id}        Removes a Supplier resource.
const routeSuppliers = '/suppliers'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// Si extendemos duplicamos instancias axios
// class Supplier extends BaseApiService { constructor() { super(axios) }
class Supplier {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchSuppliers(queryParams = {}) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeSuppliers}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // createSupplier(supplierData) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .post(`${this.API_BASE}${routeSuppliers}`, supplierData)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  // fetchSupplier(id) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .get(`${this.API_BASE}${routeSuppliers}/${id}`)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  // removeSupplier(id) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .delete(`${this.API_BASE}${routeSuppliers}/${id}`)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  // updateSupplier(supplierData) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .put(`${this.API_BASE}${routeSuppliers}/${supplierData.id}`, { ...supplierData })
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }
}

// Exportamos useSupplier (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useSupplier = new Supplier()
export default useSupplier
